const PromoBarWidget = () => ({
    isHidden: false,
    localStorageKey: "closedBlocks",

    init() {
        const {dataset} = this.$refs.PromoBar;
        this.blockId = dataset.id;
        const blocksIds = localStorage.getItem(this.localStorageKey);
        if (blocksIds !== null) {
            this.isHidden = blocksIds.includes(this.blockId);
        }
    },

    closeBlock() {
        this.isHidden = true;
        const blocksIds = localStorage.getItem(this.localStorageKey) || [];
        blocksIds.push(this.blockId);
        localStorage.setItem(this.localStorageKey, blocksIds);
    }
});

export default PromoBarWidget;

const FOUR_COL_GRID = {
    breakpointLarge: {
        template: ".four-column-grid",
        count: 4
    },
    breakpointMedium: {
        template: ".three-column-grid",
        count: 3
    },
    breakpointSmall: {
        template: ".two-column-grid",
        count: 2
    },
    breakpointNone: {
        template: ".one-column-grid",
        count: 1
    }
};

export {
    FOUR_COL_GRID
};

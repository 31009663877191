import {sampleSize} from "lodash";

import {LABELED_LINKS_MAX} from "../config/widget";

const LabeledLinksWidget = () => ({
    excludedLabels: ["nieuw", "tip"],
    source: ".section",
    links: [],
    maxLinks: null,

    init() {
        this.maxLinks = Number(this.$refs.BaseData.dataset.maxLinks) || LABELED_LINKS_MAX;

        this.links = sampleSize(this.findNodes(), this.maxLinks);
    },

    findNodes() {
        return this.excludeNodes([...document.querySelectorAll(`${this.source} .block a[data-label]`)]);
    },

    excludeNodes(arr) {
        return arr.reduce((acc, curr) => {
            !this.excludedLabels.includes(curr.dataset.label) &&
            !curr.dataset.adultContent &&
            !acc.some(node => node.textContent === curr.textContent) && acc.push(curr);
            return acc;
        }, []);
    }
});

export default LabeledLinksWidget;

import $ from "jquery";

import getConfig from "./../../umbrella/js/config";
import registerCheatCode from "./cheat-codes";

/**
 * This file contains functionality to modify banner properties we can control
 */
function positionSkyscraper(position) {
    /**
     * Have a skyscraper banner level out with the top of the first
     * widget/block in the 4th column that starts below 1300 pixels.
     */
    const bannerElement = $(`#${position}`);
    const bannerBlock = bannerElement.closest(".block");

    if (!bannerElement.length) {
        return;
    }

    setTimeout(function () {
        // find the first block that is positioned below 1300
        $(".column4 .block").each(function () {
            const offset = $(this).offset();
            if (offset && offset.top > 1300) {
                if (bannerBlock.length) {
                    bannerBlock.offset({top: offset.top});
                } else {
                    bannerElement.offset({top: offset.top});
                }
                return false;
            }
            return true;
        });
    }, 1000);
}


function displayFakeBanners() {
    /**
     * Banner sizes:
     * - 468x60
     * - 120x600
     * - 728x90
     * - 234x60
     *
     * This is called when somebody types : $banner
     */
    setHorizontal("h1");
    setHorizontal("h2");
    setVertical("v1");
    setVertical("v2");
    setVertical("v5");
}

function setVertical(id) {
    createBanner($("#" + id), 150, 600);
}

function setHorizontal(id) {
    const element = $("#" + id);
    if (element.innerWidth() >= 728) {
        createBanner(element, 728, 90);
    } else if (element.innerWidth() >= 468) {
        createBanner(element, 468, 60);
    } else {
        createBanner(element, 320, 100);
    }
}

function createBanner(element, width, height) {
    element.html(`<img src="https://lorempixel.com/${width}/${height}/nightlife" alt="Banner">`);
}

export function loadBanner(element, bannerPosition) {
    const $element = $(element);
    const adsProvider = getConfig("adsProvider", "");

    if (adsProvider === "ace") {
        if (window.loadAdvertSlot && !$element.attr("data-advert-slot-state")) {
            window.loadAdvertSlot(bannerPosition, $element.attr("id"));
        }
    }
}

$(function () {
    positionSkyscraper("v2");
    registerCheatCode("$banners", displayFakeBanners);
});

import $ from "jquery";

const cheats = new Map();

export default function registerCheatCode(code, callback) {
    cheats.set(code, callback);
}

$(() => {
    let current = "", clearTimer;

    $("body").keypress(e => {
        if (clearTimer) {
            clearTimeout(clearTimer);
        }
        clearTimer = setTimeout(() => {
            current = "";
        }, 1000);

        current += String.fromCharCode(e.which);

        for (const [code, callback] of cheats) {
            if (code === current) {
                current = "";
                clearTimeout(clearTimer);
                callback();
            }
        }
    });
});

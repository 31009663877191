import $ from "jquery";

import ProfileAgent from "./profile";

export class LocationObj {
    constructor(data = {}) {
        this.location = data.location || data.locality || "";
        this.lat = data.lat ? parseFloat(data.lat).toFixed(7) : "";
        this.lon = data.lon ? parseFloat(data.lon).toFixed(7) : "";

        this.title = "";
    }

    toString() {
        return this.title ? `${this.title} (${this.location})` : this.location;
    }
}

export class Location {
    constructor() {
        throw new TypeError("Location is a static class");
    }

    static saveLocation(location) {
        if (!(location instanceof LocationObj)) {
            location = this.locationToObj(location);
        }

        return ProfileAgent.update("locations", [], locations => {
            // add the selected location to the top of the location list
            // while making sure the same location is not added twice
            locations = locations.filter(item => item.location !== location.location);
            locations.unshift(location);

            // store at most 4 locations
            return locations.slice(0, 4);
        });
    }

    static isPostalCode(term) {
        return term.match(/[1-9][0-9]{3}\s?[A-Za-z]{2}/i);
    }

    static getLocationsByCity(query) {
        const params = {
            service: "cities",
            query: query
        };
        return this.getLocationService(params).then(
            data => ({
                matchType: "city",
                "items": data.map(item => ({
                    type: "location-name",
                    label: item.locality,
                    value: new LocationObj(item)
                })).slice(0, 3)
            })
        );
    }

    static getAutomaticLocation() {
        return this.getLocationService({service: "automatic"});
    }

    static getLocation(location) {
        const params = {};

        if (location.lat && location.lon) {
            params.service = "latlon";
            params.lat = location.lat;
            params.lon = location.lon;
        } else if (location.locality) {
            params.service = "locality";
            params.query = location.locality;
        }

        return this.getLocationService(params);
    }

    static getLocationsByQuery(locationQuery) {
        // Search is by city name
        if (Location.isPostalCode(locationQuery)) {
            locationQuery = locationQuery.replace(/\s/g, "");
        }
        return Location.getLocationsByCity(locationQuery);
    }

    static locationToObj(locationData) {
        return new LocationObj(locationData);
    }

    static updateLocation(location) {
        this.getLocation(location).then(data => {
            const locationObj = this.locationToObj(data[0]);
            $(window).trigger("location.update", [locationObj]);
            this.saveLocation(locationObj);
        });
    }

    static getLocationService(locationParams) {
        return new Promise((resolve, reject) => {
            const params = {
                type: "GET",
                url: "/api/location",
                data: locationParams,
                success: data => resolve(data),
                error: error => reject(error)
            };
            $.ajax($.extend({}, params));
        });
    }
}

import {reloadWidget} from "../api/reload-widget";
import {convertBoolFromPython} from "../helpers/convert-bool-from-python";
import {toggleBlockCollapse} from "../helpers/toggle-block-collapse";

const WidgetBase = () => ({
    isCollapsible: false,
    isCollapsed: false,
    isHidden: false,
    isTemporary: null,
    isAdult: false,
    id: null,
    placementId: null,
    isLimited: null,

    init() {
        const {BaseData} = this.$refs;
        const {id, dataset} = BaseData;
        const {canBeCollapsed, collapsedByDefault, completelyHidden, placementId, adult, widget, numberOfRowsShownByDefault} = dataset;

        this.id = id;
        this.placementId = placementId;

        this.isLimited = !!numberOfRowsShownByDefault;
        this.isCollapsible = convertBoolFromPython(canBeCollapsed);
        this.isCollapsed = convertBoolFromPython(collapsedByDefault);
        this.isHidden = convertBoolFromPython(completelyHidden);
        this.isTemporary = isNaN(placementId);
        this.isAdult = location.pathname === "/" && adult;

        this.$watch("$store.ProfileAgent.data", (data) => {
            // Re-assign Proxy result
            if (data.minimized_blocks) {
                const blocks = JSON.parse(JSON.stringify(data.minimized_blocks));
                if (blocks.length > 0 && blocks.includes(this.id)) {
                    this.isCollapsed = true;
                }
            }
        });

        this.$watch("$store.ProfileAgent.childLockActive", (data) => {
            this.isHidden = this.isAdult && data || widget === "Childlock" && !data;
        });
    },

    toggleBlock() {
        this.isCollapsed = !this.isCollapsed;
        toggleBlockCollapse(this.id, this.isCollapsed);
    },

    removeLimit() {
        const listNodes = Array.from(this.$root.getElementsByTagName("li"));
        listNodes.map(node => node.classList.remove("hidden"));
        this.isLimited = false;
    },

    addToPersonalLinks() {
        const {BlockLink} = this.$refs;
        const {dataset} = BlockLink;
        const {href, trackTrackingId, trackZlostatId, trackEventLabel} = dataset;

        const link = {
            url: href || BlockLink.href,
            title: trackEventLabel,
            tracking_id: trackTrackingId,
            zlostat_id: trackZlostatId
        };
        this.$store.PersonalLinks.addToPersonalLinks(link);
    },

    reload() {
        const {BaseData} = this.$refs;
        !this.isTemporary && reloadWidget(this.placementId, BaseData);
    }
});



export default WidgetBase;

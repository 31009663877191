const ScrollTop = () => ({
    visible: false,

    onPageScroll() {
        this.visible = window.scrollY > window.innerHeight / 2;
    },

    scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }
});

export default ScrollTop;

import $ from "jquery";

import "../../scss/widgets/block.scss";
import gettext from "../gettext";
import {isChildLockEnabled} from "../helpers/is-childlock-enabled";
import ProfileAgent from "./profile";
import WidgetBase from "./rainbow-base";

export class BlockWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.isAdult = this.element.data("adult") === true;

        if (this.isAdult) {
            if (location.pathname === "/") {
                this.addChildlockLink();
            }

            ProfileAgent.onChange("child_lock", (e, password) => {
                if (password && isChildLockEnabled()) {
                    this.element.hide();
                } else {
                    this.element.show();
                }
            });
        }
    }

    addChildlockLink() {
        const currentUl = this.element.find("ul"),
            li = $("<li />").addClass("more single-link childlock").appendTo(currentUl),
            link = $("<a />").attr({
                "href": ProfileAgent.getProfilePrivacyUrl(),
                "data-track-event-action": "hide-18-plus"})
                .appendTo(li);

        $("<span />").addClass("title").text(gettext("hide 18+ blocks")).appendTo(link);
    }
}

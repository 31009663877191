const RADIO_SELECT_CONSTANTS = [
    {identifier: "radio 1", name: "NPO Radio 1"},
    {identifier: "radio 2", name: "NPO Radio 2"},
    {identifier: "radio-3fm", name: "3FM"},
    {identifier: "radio 538", name: "Radio 538"},
    {identifier: "100%nl", name: "100% NL"},
    {identifier: "sky radio", name: "Sky Radio"},
    {identifier: "q-music", name: "Q-music"},
    {identifier: "radio veronica", name: "Radio Veronica"},
    {identifier: "radio 4", name: "NPO Radio 4"},
    {identifier: "radio 5", name: "NPO Radio 5"},
    {identifier: "arrow classic rock", name: "Arrow Classic Rock"},
    {identifier: "sublime-fm", name: "Sublime FM"},
    {identifier: "bnr nieuwsradio", name: "BNR Nieuwsradio"},
    {identifier: "caz!", name: "Arrow Caz"},
    {identifier: "classic fm", name: "Classic FM"},
    {identifier: "funx", name: "Fun-X"},
    {identifier: "pinguin radio", name: "Pinguin Radio"},
    {identifier: "radio 10 gold", name: "Radio 10"},
    {identifier: "slam fm", name: "Slam!FM"},
    {identifier: "radio decibel", name: "Radio Decibel"},
    {identifier: "radio-6", name: "NPO Radio 6"}
];

export {
    RADIO_SELECT_CONSTANTS
};

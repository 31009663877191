import Cookies from "js-cookie";

import {reloadWidget} from "../api/reload-widget";
import {convertNodetoJQuery} from "../helpers/convert-node-to-jquery";
import {logClick} from "../tracking/tracking";

const PollWidget = () => ({
    showResults: null,
    cookieName: null,
    selectedOption: null,
    placementId: null,

    init() {
        const {BaseData} = this.$refs;
        const {placementId} = BaseData.dataset;

        this.cookieName = `poll_${placementId}`;
        this.placementId = placementId;
        this.showResults = !!Cookies.get(this.cookieName);
    },

    async submitVote(url) {
        const {BaseData} = this.$refs;

        await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                "answer": this.selectedOption.value
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        }).then(() => {
            logClick(
                convertNodetoJQuery(BaseData),
                {event_action: this.placementId, event_label: this.selectedOption.label}
            );
            Cookies.set(this.cookieName, this.selectedOption.value);
            reloadWidget(this.placementId, BaseData);
            this.showResults = true;
        }).catch(err => {
            console.error(err);
        });
    }
});

export default PollWidget;

import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
import _ from "lodash";

import "../../scss/widgets/location.scss";
import gettext from "../gettext";
import {Location, LocationObj} from "./location";
import ProfileAgent from "./profile";
import WidgetBase from "./rainbow-base";

export default class LocationBasedWidget extends WidgetBase {
    constructor(element) {
        super(element);
        this.locationQuery = element.find(".location-query");
        this.defaultLocation = element.find(".location").data("default-location");
        this.locationForm = element.find(".location-form");

        this.currentLocation = new LocationObj({
            location: this.defaultLocation
        });

        this.recentLocations = [];
        this.initLocation();

        $(window).on({
            "location.update": (e, data) => this.changeDisplayLocation(data)
        });

        this.on({
            "click .location-query": () => {
                this.locationQuery.val("");
                this.toggleAutocomplete();
            },
            "focusout .location-query": () => {
                this.restoreToPrevious();
            },
            "submit .location-form": e => {
                e.preventDefault();
                this.locationQuery.autocomplete("close");
                this.updateLocation();
            },
        });

        this.locationQuery.autocomplete({
            source: _.debounce((request, response) => {
                this.locationAutocompleteSource(request, response);
            }, 1000),
            select: (e, ui) => {
                this.locationAutocompleteSelect(e, ui);
            },
            focus: (e, ui) => {
                this.locationAutocompleteFocus(e, ui);
            },
            create: () => {
                this.locationAutocompleteCreate();
            }
        });
    }

    initLocation() {
        ProfileAgent.onChange("locations", (e, locations) => {
            if (locations && locations.length) {
                this.changeDisplayLocation(new LocationObj(locations[0]));
                this.recentLocations = this.addRecentLocations(locations);
                return;
            }

            Location.getAutomaticLocation().then(
                response => {
                    const location = new LocationObj();
                    if (response.locality) {
                        location.location = response.locality;
                    }
                    this.changeDisplayLocation(location);
                },
                () => {
                    this.displayDefaultLocation();
                }
            );
        });
    }

    locationAutocompleteSource(request, response) {
        if (request.term) {
            Location.getLocationsByQuery(request.term).then(
                rows => {
                    if (rows.items.length) {
                        this.renderAutocomplete(rows.items, response);
                    }
                })
                .catch(() => this.renderAutocomplete([], response));
            return;
        }

        ProfileAgent.get("locations", []).then(
            locations => {
                this.addRecentLocations(locations);
                response(this.recentLocations);
            }
        );
    }

    locationAutocompleteSelect(e, ui) {
        $(e.currentTarget).blur();
        if (ui.item.type === "location-get-current-location") {
            this.determineLocation();

        } else if (ui.item.type === "location-default") {
            const location = {
                locality: this.defaultLocation
            };
            Location.updateLocation(location);

        } else {
            Location.saveLocation(ui.item.value);
        }
    }

    locationAutocompleteFocus(e, ui) {
        $("ul.ui-autocomplete li").removeClass("focused-item");
        $(".ui-state-focus").parent("li").addClass("focused-item");
        if (ui.item.type === "location-get-current-location") {
            e.preventDefault();
        }
    }

    locationAutocompleteCreate() {
        this.locationQuery.data("ui-autocomplete")._renderItem =
            (ul, item) => this.renderAutocompleteItem(ul, item);
    }

    renderAutocomplete(items, response) {
        if (items.length > 0) {
            const headerItem = {
                "type": "location-header",
                "label": "Suggesties"
            };
            items.unshift(headerItem);
        }

        items.push({
            "type": "location-get-current-location",
            "label": "Huidige locatie",
            "itemclass": "get-current-location",
        });

        if (this.recentLocations) {
            items = items.concat(this.recentLocations);
        }

        response(items);
    }

    addRecentLocations(locations) {
        const items = [];

        items.push({
            "type": "location-header",
            "label": "Recente locaties"
        });

        if (this.defaultLocation) {
            items.push({
                "type": "location-default",
                "label": this.defaultLocation,
            });
        }

        locations.forEach(item => {
            const location = new LocationObj(item);

            if (location.location !== this.defaultLocation) {
                items.push({
                    "type": "location-name",
                    "label": location.toString(),
                });
            }
        });

        return items;
    }

    renderAutocompleteItem(ul, item) {
        if (item.type === "location-header") {
            return $("<li class='" + item.type + "'><nobr>" + item.label + "</nobr></li>").appendTo(ul);
        } else {
            const a = $("<a class='track truncate'" +
                " data-track-event-action='select-location'" +
                " data-track-event-label='" + item.label + "'></a>").append("<nobr>" + item.label + "</nobr>");
            return $("<li class='location-item'></li>").data("item.autocomplete", item).append(a).appendTo(ul);
        }
    }

    changeDisplayLocation(location) {
        this.currentLocation = location;
        this.lat = location.lat;
        this.lon = location.lon;
        this.displayLocation();
    }

    displayDefaultLocation() {
        const defaultLocation = this.defaultLocation;
        if (defaultLocation) {
            $(".location .my-location").each(function () {
                $(this).val(defaultLocation);
            });
        }
    }

    displayLocation() {
        // TODO: don't update for blocked location widgets
        const currentLocation = this.currentLocation;
        $(".location .my-location").each(function () {
            $(this).val(currentLocation.toString());
        });
    }

    determineLocation() {
        navigator.geolocation.getCurrentPosition(
            position => {
                const location = {
                    lat: parseFloat(position.coords.latitude).toFixed(7),
                    lon: parseFloat(position.coords.longitude).toFixed(7),
                };
                Location.updateLocation(location);
            },
            () => {
                this.locationQuery.val("");
                alert("Het automatisch bepalen van uw locatie is niet gelukt.");
            },
            {enableHighAccuracy: true});
    }

    toggleAutocomplete() {
        if (!this.locationQuery.autocomplete("widget")) {
            return;
        }

        if (this.locationQuery.autocomplete("widget").is(":visible")) {
            this.locationQuery.blur();
        } else {
            this.locationQuery.autocomplete("search", this.currentLocation.location);
            this.locationQuery.closest(".block").css("z-index", 100);
        }
    }

    restoreToPrevious() {
        this.locationQuery.val(this.currentLocation.location);
    }

    updateLocation() {
        const locationQuery = this.locationQuery.val();
        if (locationQuery.length === 0) {
            alert(gettext("We could not find the location you entered."));
            return;
        }

        // Update tracking label to search query
        this.locationForm.data("trackEventLabel", locationQuery);

        Location.getLocationsByQuery(locationQuery).then(
            row => {
                if (row.items.length === 0) {
                    this.locationQuery.val("").blur();
                    this.displayLocation();
                    alert(gettext("We could not find the location you entered."));
                } else {
                    this.currentLocation.locality = row.items[0].label;
                    Location.updateLocation(this.currentLocation);
                }
            }
        );
    }
}

import {hasAllOptIns, noConsentMessage} from "../consent";
import {createObserver} from "../helpers/object-observer";

const IframeWidget = () => ({
    noConsentMsg: null,
    src: null,

    init() {
        const {dataset} = this.$refs.iframe;
        const {src} = dataset;
        this.src = src;
        createObserver(this.$root, this.isInView.bind(this));
    },

    isInView(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                hasAllOptIns() ?
                    this.$refs.iframe.setAttribute("src", this.src) :
                    this.noConsentMsg = noConsentMessage;
            }
        });
    }
});

export default IframeWidget;

import $ from "jquery";

import "../../scss/widgets/emergency-alerts.scss";
import gettext, {interpolate, ngettext} from "../gettext";
import LocationBasedWidget from "./locationbasedwidget";
import ProfileAgent from "./profile";

const itemsPerPage = 5,
    maxEmergencyAlertsAge = 120;

export class EmergencyAlertsWidget extends LocationBasedWidget {
    constructor(element) {
        super(element);
        this.on({
            "click .js-emergency-alerts-pagination": e => this.pagination(e),
            "click .personal-manage-location": e => this.manageLocation(e),
        });

        // TODO: Update utm_campaign when this widget is placed on a Thema page (SPR-1639)
        this.trackingParameters = "?utm_source=startpagina&utm_medium=widget&utm_campaign=startpagina";

        this.divContent = this.element.find(".emergency-alerts-content");
    }

    formatMessageDate(timestamp) {
        const messageUpdated = Date.now() - Date.parse(timestamp),
            day = 1000 * 60 * 60 * 24,
            hour = 1000 * 60 * 60,
            minute = 1000 * 60;

        if (messageUpdated >= day) {
            const days = Math.round(messageUpdated / day);
            return interpolate(ngettext("%s day ago", "%s days ago", days), [days]);
        }

        if (messageUpdated >= hour) {
            const hours = Math.round(messageUpdated / hour);
            return interpolate(ngettext("%s hour ago", "%s hour ago", hours), [hours]);
        }

        const minutes = Math.max(Math.round(messageUpdated / minute), 1);
        return interpolate(ngettext("%s min ago", "%s min ago", minutes), [minutes]);
    }

    updateEmergencyAlerts(data) {
        this.divContent.empty();

        if (data.length === 0) {
            const ul = $("<ul />").addClass("emergency-alerts-list").appendTo(this.divContent),
                li = $("<li />").appendTo(ul);

            li.text(gettext("There are no alerts near your location"));
            return;
        }

        const inLocation = " in " + this.currentLocation.location;
        const totalPages = Math.ceil(data.length / itemsPerPage);

        let ul;
        if (totalPages === 1) {
            ul = $("<ul />").addClass("emergency-alerts-list").appendTo(this.divContent);
        }

        for (let i = 0; i < data.length; i += 1) {
            // If we need more than one page for links
            if (i % itemsPerPage === 0 && totalPages !== 1) {
                const currentPage = Math.round(i / itemsPerPage);
                const divPage = $("<div />").attr("id", "alerting_service_" + currentPage);
                if (currentPage > 0) {
                    divPage.addClass("hidden");
                }

                divPage.appendTo(this.divContent);
                ul = $("<ul />").addClass("emergency-alerts-list").appendTo(divPage);

                const divPagination = $("<div />").addClass("emergency-alerts-pagination").appendTo(divPage),
                    ulPagination = $("<ul />").appendTo(divPagination),
                    liPaginationPrevious = $("<li />").appendTo(ulPagination),
                    liPaginationNext = $("<li />").appendTo(ulPagination),
                    buttonPrevious = $("<button />"),
                    buttonNext = $("<button />");

                if (currentPage < totalPages - 1) {
                    buttonPrevious.addClass("pagination-previous js-emergency-alerts-pagination").text("ouder");
                    buttonPrevious.attr("data-page", currentPage + 1).appendTo(liPaginationPrevious);
                }
                if (currentPage > 0) {
                    buttonNext.addClass("pagination-next js-emergency-alerts-pagination").text("nieuwer");
                    buttonNext.attr("data-page", currentPage - 1).appendTo(liPaginationNext);
                }
            }

            const li = $("<li />").appendTo(ul),
                a = $("<a />").appendTo(li),
                span = $("<span />").appendTo(a),
                messageDate = this.formatMessageDate(data[i].timestamp),
                messageUrl = data[i].url.trim() + this.trackingParameters;

            let messageTitle = data[i].title;
            if (messageTitle.substr(-inLocation.length) === inLocation) {
                messageTitle = messageTitle.substr(0, messageTitle.length - inLocation.length);
            }

            span.text(`${messageDate}: `);

            a.addClass("track hover:underline hover:text-red-400").attr("href", messageUrl).append(messageTitle);
        }
    }

    pagination(e) {
        e.preventDefault();

        const target = $(e.target),
            pageToShow = $("#alerting_service_page_" + target.data("page"));

        $("[id^=alerting_service_page_]").hide();
        pageToShow.show();
    }

    manageLocation(e) {
        e.preventDefault();

        ProfileAgent.goToLocation();
    }

    displayLocation() {
        super.displayLocation();

        if (this.isUpToDate()) {
            const data = this.retrieveData();
            if (data) {
                this.updateEmergencyAlerts(data);
                return;
            }
        }

        const location = this.currentLocation.location;
        this.getComponentData({location: location.split(" (").at(0)}).then(response => {
            this.storeData(location, response.result);
            this.updateEmergencyAlerts(response.result);
        });
    }

    isUpToDate() {
        const dataAge = (Date.now() - localStorage.emergency_alerts_updated) / 1000;
        const isSameLocation = localStorage.emergency_alerts_location === this.currentLocation.location;
        return dataAge <= maxEmergencyAlertsAge && isSameLocation;
    }

    storeData(location, data) {
        localStorage.emergency_alerts_updated = Date.now();
        localStorage.emergency_alerts_json = JSON.stringify(data);
        localStorage.emergency_alerts_location = location;
    }

    retrieveData() {
        try {
            return JSON.parse(localStorage.emergency_alerts_json);
        } catch (e) {
            delete localStorage.emergency_alerts_json;
            delete localStorage.emergency_alerts_updated;
            delete localStorage.emergency_alerts_location;
        }

        return null;
    }
}

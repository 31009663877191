export default class Utilities {
    static isNullOrUndefined(variable) {
        if (typeof variable !== "undefined" && variable !== null) {
            return false;
        } else {
            return true;
        }
    }

    static getQueryStringParameterByName(name) {
        const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    static removeQueryStringParameterFromUrl(url, parameter) {
        return url
            .replace(new RegExp("[?&]" + parameter + "=[^&#]*(#.*)?$"), "$1")
            .replace(new RegExp("([?&])" + parameter + "=[^&]*&"), "$1");
    }

    static convertFormDatatoJson(form) {
        const body = {};
        for (const formInput of new FormData(form).entries()) {
            body[formInput[0]] = formInput[1];
        }
        return JSON.stringify(body);
    }

    static setSubmitButtonState(submitButton, disable) {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (disable) {
            submitButton.disabled = true;
            if (!isIE11) {
                submitButton.querySelector(".icon-spinner").classList.remove("hidden");
            }
        } else {
            submitButton.disabled = false;
            if (!isIE11) {
                submitButton.querySelector(".icon-spinner").classList.add("hidden");
            }
        }
    }
}

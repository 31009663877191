import {roundRobin} from "../blocks";

const GridLayout = () => ({
    blocks: null,
    orderedBlocks: null,

    init() {
        const initData = this.$store.Breakpoint.data;
        this.blocks = this.getBlocks(initData.container);
        this.updateBlocks(initData);
        this.$watch("$store.Breakpoint.data", (data) => {
            this.updateBlocks(data);
        });
    },

    updateBlocks(data) {
        if (data.count === 4) {
            this.orderedBlocks = this.blocks;
        } else {
            this.orderedBlocks = this.reorderBlocks(this.blocks, data.count);
        }
        this.updateLayout(this.orderedBlocks, data);
    },

    getBlocks(container) {
        const columns = container.querySelectorAll("[class*='grid-area-col-']");
        return Array.from(columns).reduce((acc, curr, idx) => {
            const blocks = curr.querySelectorAll(":scope > .block");
            acc[idx] = blocks;
            return acc;
        }, []);
    },

    reorderBlocks(blocks, cols) {
        const result = Array.from(Array(cols), () => new Array());
        for (const [idx, block] of [...roundRobin(blocks)].entries()) {
            result[idx % cols].push(block);
        }
        return result;
    },

    createColumns(count) {
        return Array(count).fill("").map((col, idx) => {
            col = document.createElement("div");
            col.className = `grid-area-col-${idx + 1}`;
            return col;
        });
    },

    clearTarget(target) {
        return target.replaceChildren();
    },

    updateLayout(blocks, data) {
        const columnNodes = this.createColumns(data.count);
        const containerNode = document.querySelector(data.newContainer);

        [".grid-area-slot-top", ".grid-area-slot-bottom"].map(node => {
            node = data.container.querySelector(node);
            return containerNode.appendChild(node);
        });

        columnNodes.map((node, idx) => {
            node.append(...blocks[idx]);
            return containerNode.appendChild(node);
        });

        data.container !== containerNode && this.clearTarget(data.container);
    }
});

export default GridLayout;

import {reloadWidget} from "../api/reload-widget";
import {WIDGET_RELOAD_TIMEOUT} from "../config/widget";

const TrafficWidget = () => ({

    placementId: null,

    init() {
        const {BaseData} = this.$refs;
        const {placementId} = BaseData.dataset;
        this.placementId = placementId;

        setInterval(() => {
            reloadWidget(this.placementId, BaseData);
        }, WIDGET_RELOAD_TIMEOUT);
    },

});

export default TrafficWidget;

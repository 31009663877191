import cookies from "js-cookie";

/*

It is possible to automatically fold a widget if it is not used by the user.
When determining if a widget should be folded, there are two variables:

- The number of times it has been displayed
- The number of days over which the widget is displayed to the user

For example, you could set up a widget to hide after it has been displayed
five times over a period of two weeks. If a user visits the site every day, but
never uses the widget, it will hide after the fifth visit. However, if someone
visits the site only once every four days, it will never hide.

Multiple visits on the same day are counted as one.

If the user interacts with the widget, it will never be hidden automatically.

After the widget is hidden, it will never re-appear automatically.

To keep track of when the widget is displayed, three variables are stored in
a cookie:

- visible: `true` if the user has interacted with the widget,
           `false` if the widget is hidden, `null` otherwise.
- lastInteraction: the date on which the user last interacted with the widget.
- visits: an array of dates on which the widget has been displayed to the user.

*/
export default class UsageCounter {
    constructor(id, visits, days) {
        this.cookieName = "usage-" + id;
        this.visits = visits;
        this.days = days;
    }

    getCookieValue() {
        const cookieValue = cookies.getJSON(this.cookieName);
        if (cookieValue) {
            return cookieValue;
        }
        return {visible: null, lastInteraction: null, visits: []};
    }

    saveCookie(cookieValue) {
        cookies.set(this.cookieName, cookieValue, {expires: 365});
    }

    formatDate(d) {
        return d.toISOString().substr(0, 10);
    }

    getFirstDate() {
        return this.formatDate(new Date(Date.now() - this.days * 24 * 60 * 60 * 1000));
    }

    countVisit() {
        const cookieValue = this.getCookieValue();
        let changed = false;

        const today = this.formatDate(new Date());
        if (!cookieValue.visits.includes(today)) {
            cookieValue.visits.push(today);
            changed = true;
        }

        const firstDate = this.getFirstDate();
        for (let i = cookieValue.visits.length - 1; i >= 0; i -= 1) {
            if (cookieValue.visits[i] < firstDate) {
                cookieValue.visits = cookieValue.visits.slice(i);
                changed = true;
            }
        }

        if (cookieValue.visible === null && cookieValue.visits.length > this.visits) {
            cookieValue.visible = false;
            changed = true;
        }

        if (changed) {
            this.saveCookie(cookieValue);
        }
    }

    markUsed() {
        const cookieValue = this.getCookieValue();
        cookieValue.visible = true;
        cookieValue.lastInteraction = this.formatDate(new Date());
        this.saveCookie(cookieValue);
    }

    isVisible() {
        const cookieValue = this.getCookieValue();

        if (cookieValue.visible !== null) {
            return cookieValue.visible;
        }

        return true;
    }
}

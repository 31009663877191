import $ from "jquery";

import getConfig from "../../../../umbrella/js/config";
import breakpoints from "../../breakpoints";
import {loadWidgets} from "../../load-widgets";
import {matchMinWidth} from "../../responsiveness";
import {logClick} from "../../tracking/tracking";
import * as widgets from "../all";
import ProfileAgent from "../profile";
import WidgetBase from "../rainbow-base";
import SearchBox from "./search-box";

export class SearchBoxWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.queryField = $(".search-field", element);

        this.searchBox = new SearchBox(element, {
            url: getConfig("searchUrl"),
            reorderLinks: this.queryField.data("reorderLinks") === "on",
            autocomplete: this.queryField.data("autocomplete") === "on",
            headerText: this.queryField.data("headerText"),
            footerText: this.queryField.data("footerText"),
            disableAds: getConfig("disableAds", false)
        });
        this.lightbox = this.searchBox.lightbox;

        this.lightbox.container.addClass("lightbox-container-" + this.queryField.data("section"));

        this.bannerElements = $("[data-banner-position='v1'], [data-banner-position='v2']");
        this.largeBreakpoint = matchMinWidth(breakpoints.breakpointLarge);

        this.initSearchQueryUrl();

        this.searchBox.on("searchImpression", (e, data) => this.trackSearchImpression(data));

        this.lightbox.on("open", () => this.onLightboxOpen());
        this.lightbox.on("close", () => this.onLightboxClose());
        this.lightbox.on("render", (e, data) => this.onLightboxRender(data.query, !!data.response));

        this.searchBox.exitCross.on("click", () => {
            logClick(this.lightbox.container.find(".close"), {"event_label": "inputbox cross"});
        });

        ProfileAgent.onChange("child_lock", (e, password) => {
            this.searchBox.setChildLock(!!password);
        });

        this.adjustLightboxPositionWhenBannerChanges();
        this.addSearchBoxPresenceToBody();
    }

    initSearchQueryUrl() {
        // on window load check if the url contains a search query
        // only open lightbox if searchbox is visible
        if (window.location.href.includes("#q=") && this.searchBox.form.is(":visible")) {
            const query = this.getQueryFromUrl(window.location.href);
            this.queryField.val(query);
            this.searchBox.update();
        }
    }

    getQueryFromUrl(url) {
        return decodeURIComponent(url.split("#q=")[1]);
    }

    adjustLightboxPositionWhenBannerChanges() {
        // This is only necessary for the search box in the content
        if (this.element.parents(".row.columns").length === 0) {
            return;
        }

        if (!window.MutationObserver) {
            return;
        }

        const banner = $(".top-banner");
        let currentHeight = banner.height();

        const observer = new MutationObserver(() => {
            const height = banner.height();
            if (height !== currentHeight && this.lightbox.isOpen()) {
                this.lightbox.adjustPosition();
                currentHeight = height;
            }
        });

        observer.observe(banner[0], {attributes: true, childList: true, subtree: true});
    }

    onLightboxOpen() {
        loadWidgets(widgets, this.lightbox.lightbox);

        const width = document.documentElement.clientWidth;
        if (width > parseInt(breakpoints.breakpointLarge, 10)) {
            this.scrollUp();
        }

        // Hide the v1 and v2 banners if they exist, display the v3 (on large screens)
        this.bannerElements.hide();
    }

    scrollUp() {
        const top = this.element.offset().top;
        const margin = parseInt($("html").css("marginTop"), 10);
        $(window).scrollTop(top - margin);
    }

    onLightboxClose() {
        window.location.hash = "";

        // Display the v1 and v2 banners again (they were hidden when the lightbox was opened)
        this.bannerElements.show();
    }

    onLightboxRender(query, hasResult) {
        if (query && hasResult) {
            window.location.hash = "q=" + query;
        } else {
            window.location.hash = "";
        }
    }

    trackSearchImpression(data) {
        logClick(this.lightbox.content, data);
    }

    addSearchBoxPresenceToBody() {
        $("body").addClass("has-searchbox");
    }
}

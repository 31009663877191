import $ from "jquery";

import "../../../scss/widgets/searchbox/searchbox.scss";
import "../../../scss/widgets/searchbox/searchboxheader.scss";
import gettext from "../../gettext";
import SearchHistory from "./history";
import Lightbox from "./lightbox";
import SearchAutocomplete from "./search-autocomplete";

export default class SearchBox {
    constructor(element, options = {}) {
        this.element = element;
        this.form = $("form", element);
        this.queryField = $(".search-field", this.form);
        this.exitCross = $(".reset-search", this.form);
        this.suggestions = $(".suggestion-links", this.element);

        this.adjustSuggestions();

        this.form.on("submit", e => this.doSearch(e));

        this.queryField.on("keyup", e => this.keyUp(e));
        this.queryField.on("autocompleteopen", () => this.autocompleteOpen());
        this.queryField.on("autocompleteselect", () => this.autocompleteSelect());
        this.queryField.on("autocompleteclose", () => this.keyUp());

        this.element.on("click", () => this.queryField.focus());

        this.exitCross.on("click", e => {
            e.preventDefault();
            this.clear();
        });

        this.element.on("click", ".submit-button", e => {
            e.preventDefault();
            this.enterBlocked = false;
            this.form.submit();
        });

        // The option is a boolean, but we want it to be on by default.
        if (options.autocomplete || options.autocomplete === undefined) {
            this.completer = new SearchAutocomplete(this.queryField);
        } else {
            this.completer = null;
        }
        this.enableDoubleEnter = true;
        this.lightbox = new Lightbox(element, options);
        this.lightbox.on("open", () => this.onLightboxOpen());
        this.lightbox.on("close", () => this.onLightboxClose());
        this.lightbox.on("render", (e, data) => this.onLightboxRender(data.query, !!data.response));
        this.lightbox.on("google", () => this.searchGoogle());

        this.history = SearchHistory.getInstance();
        this.searchHistoryTimer = null;
        this.queryFromHistory = false;
        this.lightbox.historyPrevious.on("click", e => {
            e.preventDefault();
            this.historyPrevious();
        });
        this.lightbox.historyNext.on("click", e => {
            e.preventDefault();
            this.historyNext();
        });
        this.updateHistoryNavigation();

        this.suggestionCloseTimer = null;
        this.enterBlocked = false;

        this.searchImpressionTimer = null;
        this.lastImpressionQuery = null;
        this.lastImpressionResult = null;
        this.autocompleteClicked = false;
        this.autocompleteOriginalTerm = null;
        this.searchQueryLinkClicked = null;

        // SPR-274: Safari on the iPad does not submit the search form with
        // the 'Go' button when it is set to open in a new window.
        // SPR-806: Chrome on iPhone blocks the search windows as a popup
        if (navigator.userAgent.includes("iPad") || navigator.userAgent.includes("iPhone")) {
            this.form.attr("target", "_self");
        }

        // SPR-837, SPR-851 : On Android the cursor is set in the search field
        // by default and therefore opens up the keyboard automatically.
        // On Android and chrome the resize function is triggered
        // continuously on scrolling (bug?) and this is initializing the focus
        // again. Solution is to disable this functionality as a whole on
        // Android devices.
        if (!navigator.userAgent.includes("Android")) {
            this.initFocusSearchField();
        }

        this.initSearchQueryLinks();

        if (options.childlock !== undefined) {
            this.setChildLock(options.childlock);
        }

        if (options.disableAds !== undefined) {
            this.setDisableAds(options.disableAds);
        }
    }

    trigger(eventType, data) {
        const event = $.Event(eventType);
        $(this).triggerHandler(event, data);
        return event;
    }

    on(eventType, handler) {
        $(this).on(eventType, handler);
    }

    initFocusSearchField() {
        this.focusSearchField();

        // This condition check if browser = ie11, ie10
        if (!window.ActiveXObject && "ActiveXObject" in window) {
            $(window).resize(() => {
                this.focusSearchField();
            });
        } else {
            $(window).on("resize", () => {
                this.focusSearchField();
            });
        }
    }

    focusSearchField() {
        if (this.queryField.is(":visible")) {
            // always focus on top searchbox
            try {
                document.querySelector(".grid-area-slot-top .search-field, .section-wide .search-field").focus();
            } catch (e) {
                console.warn("Failed to set focus to the first search field: not found");
            }
        }
    }

    initSearchQueryLinks() {
        // attach event handler to #q=query links
        // when clicked fill searchbox and open lightbox
        $("body").on("click", "a[href^='#q=']", e => {
            // only open lightbox if searchbox is visible
            if (this.form.is(":visible")) {
                e.preventDefault();
                this.searchQueryLinkClicked = e.currentTarget;
                const query = decodeURIComponent(e.currentTarget.href.split("#q=")[1]);
                this.queryField.val(query);
                this.update();
            }
        });
    }

    doSearch(event) {
        this.query = this.queryField.val();
        if (!this.query) {
            event.preventDefault();
            window.alert(gettext("Please enter a search query"));
            this.queryField.focus();
            return false;
        }

        if (this.enterBlocked && this.enableDoubleEnter) {
            event.preventDefault();
            this.enterBlocked = false;
            this.closeSuggestions();
            return false;
        }
        this.form.data("trackQuery", this.query);
        return true;
    }

    update() {
        const query = this.queryField.val();
        this.exitCross.toggle(query !== "");
        this.lightbox.update(query);
    }

    keyUp(e) {
        this.queryFromHistory = false;
        this.update();

        if (e && e.which !== 13) {
            this.enterBlocked = this.queryField.val() !== "";
        }
    }

    clear() {
        this.queryField.val("").focus();
        this.exitCross.hide();
        this.lightbox.close();
    }

    searchGoogle() {
        this.enterBlocked = false;
        this.form.submit();
        this.clear();
    }

    onLightboxOpen() {
        if (this.suggestionCloseTimer) {
            clearTimeout(this.suggestionCloseTimer);
        }

        this.element.find("form").css("z-index", "9999");
        this.element.find("form").css("max-width", "clamp(20rem, 50vw, 40rem)");
        this.suggestionCloseTimer = setTimeout(() => this.closeSuggestions(), 8000);
        this.enableDoubleEnter = this.queryField.data("enableDoubleEnter") === "True";
    }

    onLightboxClose() {
        this.enterBlocked = false;
        this.element.find("form").css("z-index", "");
        this.element.find("form").css("max-width", "");
        this.exitCross.toggle(this.queryField.val() !== "");
    }

    onLightboxRender(query, hasResult) {
        if (this.searchImpressionTimer) {
            clearTimeout(this.searchImpressionTimer);
        }

        if (query.length >= this.lightbox.minLength) {
            this.searchImpressionTimer = setTimeout(() => this.trackSearchImpression(query, hasResult), 1000);
        } else {
            this.searchImpressionTimer = null;
        }

        if (this.searchHistoryTimer) {
            clearTimeout(this.searchHistoryTimer);
        }
        if (hasResult && !this.queryFromHistory && this.lightbox.content.find(".adult").length === 0) {
            this.searchHistoryTimer = setTimeout(() => {
                this.history.add(query);
                this.updateHistoryNavigation();
            }, 1000);
        }

        if (query && !hasResult && this.autocompleteClicked) {
            /**
             * Change the form target to _self because if we submit this _blank
             * after the AJAX request it's blocked by the popup blocker.
             */
            this.form.attr("target", "_self");
            this.searchGoogle();
        }
    }

    trackSearchImpression(query, hasResult) {
        if (this.searchImpressionTimer) {
            clearTimeout(this.searchImpressionTimer);
            this.searchImpressionTimer = null;
        }

        if (this.lastImpressionQuery === query && this.lastImpressionResult === hasResult) {
            return;
        }

        this.lastImpressionQuery = query;
        this.lastImpressionResult = hasResult;

        const data = {
            event_type: "search-impression",
            event_category: "search",
            event_action: "Search query input",
            event_label: query,
            query: query,
            has_results: hasResult,
        };

        if (hasResult) {
            data.page_tracking_id = this.lightbox.content
                .find("[data-track-page-tracking-id]")
                .data("trackPageTrackingId");
        }

        if (this.autocompleteClicked) {
            data.event_action = "Google suggestion click:" + this.autocompleteOriginalTerm;
            this.autocompleteClicked = false;
            this.autocompleteOriginalTerm = null;
        }

        if (this.searchQueryLinkClicked) {
            const link = $(this.searchQueryLinkClicked);
            const block = link.parents("[data-track-block-id]");

            data.event_action = "Hash click";
            data.hashclick_tracking_id = link.data("trackTrackingId");
            data.hashclick_block_id = block.data("trackBlockId");
            data.hashclick_block_tracking_id = block.data("dataTrackBlockTrackingId");

            this.searchQueryLinkClicked = null;
        }


        this.trigger("searchImpression", data);
    }

    autocompleteOpen() {
        this.autocompleteOriginalTerm = this.queryField.val();
    }

    autocompleteSelect() {
        this.autocompleteClicked = true;
    }

    closeSuggestions() {
        if (this.suggestionCloseTimer) {
            clearTimeout(this.suggestionCloseTimer);
            this.suggestionCloseTimer = null;
        }

        if (this.completer) {
            this.completer.close();
        }
    }

    updateHistoryNavigation() {
        this.lightbox.historyPrevious.toggleClass("visible", this.history.hasPrevious());
        this.lightbox.historyNext.toggleClass("visible", this.history.hasNext());
    }

    historyPrevious() {
        this.queryFromHistory = true;
        this.queryField.val(this.history.previous());
        this.update();
        this.updateHistoryNavigation();
    }

    historyNext() {
        this.queryFromHistory = true;
        this.queryField.val(this.history.next());
        this.update();
        this.updateHistoryNavigation();
    }

    setChildLock(childLockEnabled) {
        if (childLockEnabled) {
            this.lightbox.params.ks = "on";
        } else {
            delete this.lightbox.params.ks;
        }
    }

    setDisableAds(disableAds) {
        if (disableAds) {
            this.lightbox.params.disable_ads = "on";
        } else {
            delete this.lightbox.params.disable_ads;
        }
    }
    adjustSuggestions() {
        const containerWidth = this.form.width();
        let totalWidth = 0;

        for (const element of this.suggestions.children()) {
            totalWidth += element.offsetWidth;
            if (totalWidth > containerWidth) {
                element.remove();
            }
        }
    }
}

import $ from "jquery";

import getConfig from "../../umbrella/js/config";

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

export function beforeSend(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !settings.crossDomain) {
        xhr.setRequestHeader("X-CSRFToken", getConfig("csrf_token", "Startpagina Sparkle"));
    }
}

$.ajaxSetup({
    beforeSend: beforeSend
});

import {reloadWidget} from "../api/reload-widget";
import {WIDGET_RELOAD_TIMEOUT} from "../config/widget";
import {createObserver} from "../helpers/object-observer";

const FeedWidget = () => ({
    isActive: false,

    init() {
        const {BaseData} = this.$refs;
        const {placementId} = BaseData.dataset;

        setInterval(() => {
            if (this.isActive && !isNaN(placementId)) {
                reloadWidget(placementId, BaseData);
            }
        }, WIDGET_RELOAD_TIMEOUT);

        createObserver(BaseData, this.isInView.bind(this));
    },

    isInView(entries) {
        entries.forEach((entry) => {
            this.isActive = entry.isIntersecting;
        });
    }
});

export default FeedWidget;

import "../../../scss/intermediate-design/widgets/banner.scss";
import {loadBanner} from "../../banners";
import WidgetBase from "../../widgets/rainbow-base";

export class BannerWidget extends WidgetBase {
    constructor(element) {
        super(element);

        loadBanner(element.find(".ad-widget"), element.data("banner-position"));
    }
}

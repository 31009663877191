/*
 Implement responsive behaviour for blocks in columns
 - ultra big layout shows 5 columns of fixed width (handled using media query)
 - large layout show 4 columns of fixed width, 5th column moves under the 4th
   column (handled using media query)
 - medium layout shows 3 columns of fixed width, 4th column including fifth is
   hidden (handled using media query)
 - small layout shows 2 columns using all available space. All visible blocks
   are redistributed over these 2 columns according to these rules:
  -- 2 columns widgets first
  -- 1 column widgets next 123,456,789 becomes 12,34,56,78,9
 - tiny layout shows 1 column using all available width according to these rules
  -- 2 columns widgets first, rezised to 1 column
  -- 1 column widgets next 12,34,56,78,9 becomes 1,2,3,4,5,6,7,8,9
 */
import $ from "jquery";

import getConfig from "../../umbrella/js/config";
import {BlockToSectionAdder, getBlockIterator, checkScrollTopNavigation, resetTopNavigation} from "./blocks.js";
import breakpoints from "./breakpoints";

function createSection(className) {
    const div = $("<div/>", {class: "section"});
    if (className) {
        div.addClass(className);
    }
    return div;
}

/*
 * Select - and if needed create - the div blocks will be added to.
 * >= medium is original columns
 * >= small in two column
 * otherwise one column
*/
function getTargetContainer() {
    if (mediumBreakpoint.matches) {
        return "responsive-column";
    }
    if (smallBreakpoint.matches) {
        return "two-columns";
    }
    return "one-column";
}

function createTargetContainer(className) {
    let container = $("." + className);

    if (container.length) {
        return container;
    }

    container = $("<div />", {class: className});
    container.append($("<div/>", {class: "iframe-section"}));
    container.append(createSection("section-wide"), createSection());
    container.insertBefore(".responsive-column");

    if (className === "two-columns") {
        container.append(createSection());
    }

    return container;
}

/* Get the container that currently holds the blocks
 */
export function getBlockContainer() {
    // Find where the blocks are
    if ($(".responsive-column .block").length) {
        return $(".responsive-column");
    }

    if ($(".two-columns .block").length) {
        return $(".two-columns");
    }

    if ($(".one-column .block").length) {
        return $(".one-column");
    }

    return null;
}

function reorderBlocks(blockContainer, targetContainer) {
    if (blockContainer.is(targetContainer)) {
        return;
    }

    // Get all iframe-sections and put them first
    $(".iframe-section", targetContainer)
        .append($(".iframe-section > .block", blockContainer));

    // Get all wide-sections and put them second
    $(".section-wide", targetContainer)
        .append($(".section-wide > .block", blockContainer));

    const targetSections = $(".section", targetContainer).not(".section-wide");
    const blockAdder = new BlockToSectionAdder(targetSections.length);
    for (const block of getBlockIterator(blockContainer)) {
        blockAdder.addBlock(block);
    }

    const sectionBlocks = blockAdder.getSections();
    targetSections.each((index, targetSection) => {
        $(targetSection).append(sectionBlocks[index]);
    });
}

export function inspectPage(reorderBlocks) {
    const blockContainer = getBlockContainer();
    if (!blockContainer) {
        return;
    }

    const targetContainerClass = getTargetContainer();
    const targetContainer = createTargetContainer(targetContainerClass);

    if (!blockContainer.hasClass("two-columns") && !blockContainer.hasClass("one-column")) {
        resetTopNavigation();
    }

    reorderBlocks(blockContainer, targetContainer);
}

function addGoToTop() {
    const goToTop = $("#go-to-top");

    if (window.pageYOffset > 100) {
        goToTop.fadeIn();
    } else {
        goToTop.hide();
    }
}

/*
 * This function adds an event handler that is called whenever the screen
 * changes to be more or less than `minWidth` pixels wide. It uses a media
 * query if they are supported by the browser, otherwise it inspects the
 * document when the `resize` event is triggered.
 *
 * The returned object has a `matches` property, which is `true` when the
 * screen is more than `minWidth` pixels wide.
 */
export function matchMinWidth(minWidth, callback) {
    minWidth = parseInt(minWidth, 10);

    if (window.matchMedia) {
        const mq = window.matchMedia("(min-width: " + minWidth + "px)");
        if (callback) {
            mq.addListener(() => callback(mq.matches, mq));
        }
        return mq;
    }

    const mq = {matches: undefined};

    function resizeHandler() {
        const last = mq.matches;

        mq.matches = document.documentElement.clientWidth >= minWidth;

        if (mq.matches !== last && callback) {
            callback(mq.matches, mq);
        }
    }

    resizeHandler();

    // This condition check if browser = ie11, ie10
    if (!window.ActiveXObject && "ActiveXObject" in window) {
        $(window).resize(resizeHandler);
    } else {
        $(window).on("resize", resizeHandler);
    }

    return mq;
}

export function addScrollHandler() {
    let lastScrollTop = 0;

    const goToTopBreakpoint = matchMinWidth(breakpoints.breakpointMedium);
    const alwaysShow = getConfig("alwaysShowBackToTop", false);

    $(window).on("scroll", () => {
        if (alwaysShow || !goToTopBreakpoint.matches) {
            // Go to top button management
            addGoToTop();
        }

        if (!goToTopBreakpoint.matches) {
            // Scrolling top navigation
            checkScrollTopNavigation(lastScrollTop);
        }

        lastScrollTop = window.pageYOffset;
    });

    $("#go-to-top").on("click", () => {
        $("html, body").animate({scrollTop: 0});
        return false;
    });
}

export let smallBreakpoint, mediumBreakpoint, largeBreakpoint;

export function initBlockReordering(callback) {
    smallBreakpoint = matchMinWidth(breakpoints.breakpointSmall, callback);
    mediumBreakpoint = matchMinWidth(breakpoints.breakpointMedium, callback);
    largeBreakpoint = matchMinWidth(breakpoints.breakpointLarge, callback);

    // This is called on document ready, so we immediately inspect the page
    // and reorder blocks if necessary.
    const matching = [largeBreakpoint, mediumBreakpoint, smallBreakpoint].find((breakpoint) => breakpoint.matches);
    callback(true, matching);
}

export function initRainbowBlockReordering() {
    if ($(".responsive-column").length === 0) {
        return;
    }

    initBlockReordering(() => {
        inspectPage(reorderBlocks);
    });
}

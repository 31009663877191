import Alpine from "alpinejs";

import {isChildLockEnabled} from "../helpers/is-childlock-enabled";
import ProfileAgent from "../widgets/profile";

Alpine.store("ProfileAgent", {
    data: null,
    isLoggedIn: null,
    childLockActive: null,
    childLockPassword: null,
    privacyUrl: null,
    linksPerPage: 5,
    paginatedLinks: [],
    currentPage: 1,
    totalPages: 1,
    visiblePages: [1],

    init() {
        ProfileAgent.accountInfo = ProfileAgent.init();

        ProfileAgent.on("login", () => {
            this.isLoggedIn = ProfileAgent.isLoggedIn();
        });

        ProfileAgent.onChange("child_lock", (e, password) => {
            this.childLockActive = !!(password && isChildLockEnabled());
            this.childLockPassword = password;
        });

        this.privacyUrl = ProfileAgent.getProfilePrivacyUrl();

        ProfileAgent.onChange("*", (e, data) => {
            if (data.uid) {
                this.data = data;
            } else {
                // Localstorage does not trigger $watch
                this.data = JSON.parse(JSON.stringify(data));
            }
            this.totalPages = Math.ceil(data.links.length / this.linksPerPage);
            this.setPagination(this.currentPage, this.totalPages);
        });
    },

    update(key, data) {
        return ProfileAgent.update(key, data);
    },

    set(key, value) {
        return ProfileAgent.set(key, value);
    },

    showPaginatedLinks(idx) {
        const firstIdx = (idx - 1) * this.linksPerPage;
        const lastIdx = this.linksPerPage * idx;
        this.paginatedLinks = this.data.links.slice(firstIdx, lastIdx);
        this.currentPage = idx;
    },

    setPagination(current, total = this.totalPages) {
        if (current > this.totalPages) {
            this.currentPage = this.totalPages;
            this.$store.ProfileAgent.showPaginatedLinks(this.currentPage);
        }

        let range = [];
        let i = 1;

        const belowRange = current < 3;

        const aboveRange = current > total - 2;
        const inRange = current >= 3 && current <= total - 2;

        switch (true) {
            case total <= 5:
                while (i <= total) {
                    range.push(i);
                    i++;
                }
                break;
            case belowRange:
                range = [1, 2, 3, "…", total];
                break;
            case aboveRange:
                range = [1, "…", total - 2, total - 1, total];
                break;
            case inRange:
                range = [1, "…", current, "…", total];
                break;
        }
        this.visiblePages = range;
    },
});

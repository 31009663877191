import gettext from "../gettext";

const ChildLockWidget = () => ({
    currentPassword: null,
    password: "",
    errorMsg: null,

    init() {
        this.$watch("$store.ProfileAgent.childLockPassword", (password) => {
            this.currentPassword = password;
        });
    },

    submit() {
        if (this.password === "") {
            this.errorMsg = gettext("Mandatory!");
        } else if (this.password === this.currentPassword) {
            this.$store.ProfileAgent.set("child_lock", "");
        } else {
            this.errorMsg = gettext("The entered password is not correct.");
        }
    }
});

export default ChildLockWidget;

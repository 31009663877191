import $ from "jquery";
import {omitBy, isNull} from "lodash";

import getConfig from "../../../umbrella/js/config";
import getSeaTracker from "./sea";


function toSnakeCase(s) {
    return s.replace(/(.)([A-Z][a-z]+)/, "$1_$2").replace(/([a-z0-9])([A-Z])/, "$1_$2").toLowerCase();
}

export function getTrackingData(element) {
    const trackingData = {};

    for (const e of element.parents().addBack()) {
        const data = $(e).data();
        if (data) {
            for (const key of Object.keys(data)) {
                if (key.indexOf("track") === 0) {
                    trackingData[toSnakeCase(key.substr(5))] = data[key];
                }
            }
        }
    }
    return trackingData;
}

function hasTrackingData(element) {
    if (element.hasClass("track")) {
        return true;
    }

    return Object.keys(element.data()).some(key => key.indexOf("track") === 0);
}

function getUrl(element) {
    const tagName = element.prop("tagName").toLowerCase();

    if (element.data("url")) {
        return element.data("url");
    }

    if (tagName === "a" && element.attr("href")) {
        return element.attr("href");
    }
    if (tagName === "form") {
        let action = element[0].action;
        if (action.includes("?")) {
            action = action.substr(0, action.indexOf("?"));
        }
        return action + "?" + element.serialize();
    }

    return null;
}

function loadPixel(url) {
    if (typeof Image !== "undefined") {
        new Image().src = url;
    }
}

const clickUrl = getConfig("clickUrl");

function logClickTracker(data) {
    const params = {
        link: data.tracking_id,
        block: data.block_tracking_id,
        search_page: data.search_page_tracking_id,
        page: data.page_tracking_id,
        referer: window.location.href,
        user_agent: navigator.userAgent,
        channel: data.channel,
        event_action: data.event_action,
        event_label: data.event_label,
        url: data.link_url,
    };
    loadPixel(clickUrl + "?" + $.param(omitBy(params, isNull)));
}

export function googleAnalyticsEvent(data) {
    window.dataLayer.push({
        "event": "Clickout link",
        "eventCategory": data.event_category,
        "eventAction": data.event_action,
        "eventLabel": data.event_label || data.url,
        "pageId": data.page_tracking_id,
        "blockId": data.block_tracking_id,
        "trackingId": data.tracking_id
    });
}

export function logClick(element, extraData) {
    // If the element that is clicked does not have any tracking data,
    // find the closest element with the 'track' class and use that.
    if (!hasTrackingData(element)) {
        element = element.closest(".track");
    }
    if (element.length === 0) {
        return;
    }

    const trackingData = getTrackingData(element);

    if (extraData) {
        $.extend(trackingData, extraData);
    }

    if (trackingData.do_not_track) {
        return;
    }

    trackingData.link_url = getUrl(element);
    trackingData.visible_url = element.data("origHref");

    if (!trackingData.event_type) {
        if (trackingData.link_url && trackingData.link_url !== "#") {
            trackingData.event_type = "exit-click";
        } else {
            trackingData.event_type = "click";
        }
    }

    if (!trackingData.event_action) {
        trackingData.event_action = $.trim(element.text());
    }

    // Only fire the SEA tracker when the link is commercial. We treat a link
    // as 'commercial' when it has a contract (with Zlostat ID).
    if (trackingData.zlostat_id) {
        const seaTracker = getSeaTracker();
        seaTracker.fire();

        trackingData.gclid = seaTracker.getGclid();
        trackingData.bingid = seaTracker.getBingID();
    }

    trackingData.channel = trackingData.channel || "home";

    if (trackingData.tracking_id) {
        logClickTracker({
            ...trackingData,
            block_tracking_id: element.closest("[data-track-block-tracking-id]")[0]?.dataset.trackBlockTrackingId || null,
            search_page_tracking_id: element.closest("[data-track-page-tracking-id]")[0]?.dataset.trackPageTrackingId || null,
            page_tracking_id: getConfig("pageTrackingId", null),
        });
    }

    for (const key of Object.keys(trackingData)) {
        if (trackingData[key] === undefined || trackingData[key] === null) {
            delete trackingData[key];
        }
    }

    googleAnalyticsEvent(trackingData);
}

$(() => {
    const body = $("body");

    // Global link click handler.
    body.on("click", "a, .track", e => {
        const link = $(e.currentTarget);

        logClick(link);

        if (link && link.attr("target") === "_self") {
            e.preventDefault();
            setTimeout(() => {
                window.location = link.attr("href");
            }, 200);
        }
    });

    // Log change on select
    body.on("change", ".select-track", e => {
        const option = e.currentTarget.options[e.currentTarget.selectedIndex];
        logClick($(option));
    });

    // Global form submit handler.
    body.on("submit", "form", e => {
        logClick($(e.currentTarget));
    });

    body.on("mouseup", "a", e => {
        // Middle mouse button
        if (e.which === 2) {
            const link = $(e.currentTarget);
            logClick(link);
        }
    });
});

import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";

import "../../../scss/widgets/searchbox/search-autocomplete.scss";

export default class SearchAutocomplete {

    constructor(searchField) {
        this.searchField = searchField;
        this.autoCompleter = new GoogleCompleter();

        this.searchField.autocomplete({
            minLength: 2,
            delay: 200,
            classes: {
                "ui-autocomplete": "search"
            },
            source: (request, response) => this.autoCompleter.source(request, response),
            messages: {
                noResults: "",
                results: () => ""
            }
        });
        this.searchField.autocomplete("instance")._renderItem = (ul, item) =>
            this.autoCompleter.renderItem(ul, item);
    }

    close() {
        this.searchField.autocomplete("close");
    }
}

export class BaseCompleter {

    constructor() {
        this.maxSuggestions = 3;
    }

    renderItem(ul, item) {
        return $("<li/>")
            .data("value", item.value)
            .append(item.label)
            .appendTo(ul);
    }

    source(request, response) { // eslint-disable-line no-unused-vars
        throw new Error("Not implemented");
    }

    processData(data) {
        return data;
    }
}

export class GoogleCompleter extends BaseCompleter {

    constructor() {
        super();
        this.url = "https://clients1.google.com/complete/search";
        this.params = {hl: "nl", client: "firefox", inputencoding: "UTF-8", outputencoding: "UTF-8"};
    }

    source(request, response) {
        const params = Object.assign({}, this.params, {q: request.term});

        $.ajax({
            url: this.url,
            data: params,
            dataType: "jsonp",
            success: data => response(this.processData(request.term, data)),
        });
    }

    processData(query, data) {
        const suggestions = data[1].slice(0, this.maxSuggestions);
        return suggestions.map(term => ({
            value: term,
            label: term.replace(query, `<strong>${query}</strong>`),
        }));
    }
}

/**
 * Check if the url is valid. Regex is a modification of the one used in
 * Django https://github.com/django/django/blob/b1afebf882db5296cd9dcea26ee66d5250922e53/django/core/validators.py#L78
 *
 * @param url
 * @return boolean
 */
export default function isValidUrl(url) {
    // eslint-disable-next-line max-len
    return /^(http|https|ftp|ftps):\/\/(\S+(:\S*)?@)?((25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}|\[[0-9a-f:.]+\]|([a-z\u00a1-\uffff0-9]([a-z\u00a1-\uffff0-9-]{0,61}[a-z\u00a1-\uffff0-9])?(\.[a-z\u00a1-\uffff0-9]([a-z\u00a1-\uffff0-9-]{0,61}[a-z\u00a1-\uffff0-9])?)*\.([a-z\u00a1-\uffff-]{2,63}|xn--[a-z0-9]{1,59})\.?|localhost))(:\d{2,5})?([/?#][^\s]*)?$/i.test(url);
}

import $ from "jquery";

import "../../scss/widgets/insurance.scss";
import WidgetBase from "./rainbow-base";

export class InsuranceWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.on({
            "click .insurance-tab": e => this.onTabClick(e),
        });

        this.frameContainer = element.find(".frame-container");
        this.activeTab = element.find(".insurance-tab.active");

        this.loadScript(this.frameContainer.data("initialTab"));

        window.addEventListener("resize", () => this.setFrameHeight());
    }

    onTabClick(e) {
        const target = $(e.currentTarget);

        if (target.is(".active")) {
            return;
        }

        this.activeTab.removeClass("active");
        this.activeTab = target;
        this.activeTab.addClass("active");

        this.loadScript(target.data("tab"));
    }

    getFrameWindow() {
        const frame = this.frame[0];
        return frame.contentWindow || frame.contentDocument.document || frame.contentDocument;
    }

    setFrameHeight() {
        const frameWindow = this.getFrameWindow();
        const height = frameWindow.document.body.scrollHeight;
        this.frameContainer.height(height);
        this.frame.height(height);
    }

    loadScript(tab) {
        const scripts = {
            "auto": "https://code.komparu.com/jDU9a",
            "wa": "https://code.komparu.com/RzOz2",
            "inboedel": "https://code.komparu.com/27lAq",
            "opstal": "https://code.komparu.com/97gC7",
            "recht": "https://code.komparu.com/vjz0t",
            "reis": "https://code.komparu.com/41Arf",
            "energie": "https://code.komparu.com/KVMyp",
            "zorg": "https://code.komparu.com/__healthcare2.startpagina"
        };

        this.frameContainer.empty();

        // Generate a random name for the iframe. Without this name, some
        // browsers will load the page itself when coming back to this page
        // using the next or previous buttons.
        const name = "komparu-" + parseInt(Math.random() * 100000, 10);
        this.frame = $("<iframe />").attr("name", name).attr("scrolling", "no").appendTo(this.frameContainer);

        const frameWindow = this.getFrameWindow();
        frameWindow.document.open();
        frameWindow.document.write(`<div id="kz"><script src="${scripts[tab]}"></script></div>`);
        frameWindow.document.close();

        setTimeout(() => this.setFrameHeight(), 2000);
    }
}

import $ from "jquery";

import "../../scss/widgets/daughter-search.scss";
import WidgetBase from "./rainbow-base";

/**
 * Daughter search widget allows you to search in the available daughter
 * pages. When no match can be found in the existing daughterpages it will
 * search within the aliases to match a possible daughterpage.
 * When the user presses enter or the 'Go' button it will submit the form
 * and the visitor will be send to startgoogle.startpagina.nl.
 */
export class DaughterSearchWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.delayTimer = null;
        this.daughterSearchQueryElement = $(".daughter-search-query", this.element);

        this.element.on({
            "keyup .daughter-search-query": () => this.keyUpSearch(),
        });
    }

    /**
     * Initialize a search, refresh the current widget content by injecting
     * html retrieved from the backend. There's a timeout of 500 ms before the
     * request is executed, to prevent making multiple requests while the user
     * is still typing.
     */
    keyUpSearch() {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            const query = this.daughterSearchQueryElement.val();
            if (query.length > 0) {
                this.reloadComponentHtml(
                    "[data-widget-content]",
                    {"query": query}
                );
            }
        }, 500);
    }
}

import Cookies from "js-cookie";

import getConfig from "../../../umbrella/js/config";
import {emailRegexp} from "../validation/validation-checks";

const NewsletterWithImageAndUsp = () => ({
    newsLetterApiUrl: null,
    isSubscribed: null,
    formData: {},
    errorMessages: {
        email: true,
        gender: true
    },
    hasSubscribed: false,
    isSubmitting: false,
    csrfToken: null,

    init() {
        this.csrfToken = Cookies.get("csrftoken");
        this.newsLetterApiUrl = getConfig("newsletterApiUrl");
        this.$watch("$store.ProfileAgent.data", (data) => {
            this.isSubscribed = !!data.newsletter;
            this.formData.email = data.email || "";
        });
    },

    validate(formData) {
        this.errorMessages.email = emailRegexp.test(formData.email);
        this.errorMessages.gender = ["M", "F", "O", "N"].includes(formData.gender);

        return this.errorMessages.email && this.errorMessages.gender;
    },

    submit() {
        if (!this.validate(this.formData)) {
            return;
        }

        const options = {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": this.csrfToken,
            },
            referrerPolicy: "no-referrer-when-downgrade",
            body: JSON.stringify({
                ...this.formData,
            }),
        };

        this.isSubmitting = true;

        fetch(this.newsLetterApiUrl, options).then(response => {
            switch (response.status) {
                case 201:
                    this.hasSubscribed = true;
                    break;
                case 400:
                    response.json().then(data => {
                        this.errorMessages.error = data.gender || data.email || "";
                        this.errorMessages.generic = !data.gender && !data.email;
                    });
                    break;
                default:
                    this.errorMessages.generic = true;
                    break;
            }
            this.isSubmitting = false;
        }).catch(err => {
            console.error("Failure during fetch", err);
            this.errorMessages.generic = true;
            this.isSubmitting = false;
        });

    }

});

export default NewsletterWithImageAndUsp;

import ProfileAgent from "../widgets/profile";

export const toggleBlockCollapse = (id, collapsed) => {
    ProfileAgent.getData().then((data) => {
        collapsed ? minimizeBlock(id, data) : maximizeBlock(id, data);
        return ProfileAgent.set(data);
    });
};

const minimizeBlock = (id, data) => {
    data.maximized_blocks = data.maximized_blocks.filter(block => block !== id);
    data.minimized_blocks.push(id);

    return data;
};

const maximizeBlock = (id, data) => {
    data.minimized_blocks = data.minimized_blocks.filter(block => block !== id);
    data.maximized_blocks.push(id);

    return data;
};

import $ from "jquery";

function unmaskUrl(link) {
    link.data("origHref", link.attr("href"));
    link.attr("href", link.data("href"));
}

function maskUrl(link) {
    link.attr("href", link.data("origHref"));
}

function startLinkMasking() {
    const body = $("body");

    body.on("mousedown.link-masking", "a[data-href]", e => {
        unmaskUrl($(e.currentTarget));
    });

    body.on("mouseup.link-masking", "a[data-href]", e => {
        const link = $(e.currentTarget);
        setTimeout(() => {
            maskUrl(link);
        }, 500);
    });
}

function enableLinkMasking() {
    const body = $("body");

    for (const link of body.find("a[data-href]")) {
        maskUrl($(link));
    }

    startLinkMasking();
}

function disableLinkMasking() {
    const body = $("body");

    body.off(".link-masking");

    for (const link of body.find("a[data-href]")) {
        unmaskUrl($(link));
    }
}

$(() => {
    startLinkMasking();

    $("#mask-urls").on("change", e => {
        if (e.target.checked) {
            enableLinkMasking();
        } else {
            disableLinkMasking();
        }
    });
});

import {loadPlayer} from "../../../videofeeds/js/agnoplay.js";
import "../../scss/widgets/videofeeds-video.scss";
import WidgetBase from "./rainbow-base";

export class VideofeedsVideoWidget extends WidgetBase {
    constructor(element) {
        super(element);
        this.loadPlayer();
    }

    loadPlayer() {
        // config options override console settings when specified
        // see https://www.agnoplay.com/cp/docs/implementation/ under "Principles"

        const config = {
            videoId: this.element.data("video-id"),
            autoplay: this.element.data("autoplay") === "True",
            no_ads: this.element.data("show-ads") !== "True"
        };

        loadPlayer(this.element.find(".video-container")[0], config);
    }
}

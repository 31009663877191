/*global __tcfapi: false */
import $ from "jquery";

import gettext from "./gettext";

const adSenseUrl = /\/\/pagead2\.googlesyndication\.com\/pagead\/js\/adsbygoogle\.js/;
let adSenseLoaded = false;

// Purposes list: https://support.google.com/campaignmanager/answer/10031693?hl=en
const consentTypes = {
    "Store and/or access information on a device": 1,
    "Select basic ads": 2,
    "Select personalised ads": 3,
    "Create a personalised ads profile": 4,
    "Measure ad performance": 7,
    "Apply market research to generate audience insights": 9,
    "Develop and improve products": 10,
    // These are not in use by Google
    // 8: "Personalisatie",
    // 9: "Marketing",
    // 10: "Sociale media",
};

export function loadScript(url) {
    // Try to prevent loading AdSense more than once. This is mainly to prevent
    // issues with multiple Thema banners on the same page that contain an
    // AdSense script tag. See also SPR-2027.
    if (url.match(adSenseUrl)) {
        if (adSenseLoaded) {
            return;
        }
        adSenseLoaded = true;
    }

    $.ajax({
        url: url,
        type: "GET",
        dataType: "script",
        cache: true,
        async: false,
        global: false,
        "throws": true
    });
}

function execScripts(element) {
    /* The logic in this function is largely borrowed from jQuery:
     * https://github.com/jquery/jquery/blob/1.12-stable/src/manipulation.js#L241-L252
     * but modified to add 'cache: true' to the ajax call. */
    const rcleanScript = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g;

    $("script", element).each(function () {
        if (this.src) {
            loadScript(this.src);
        } else {
            const scriptContent = this.text || this.textContent || this.innerHTML || "";
            $.globalEval(scriptContent.replace(rcleanScript, ""));
        }
    });
}

const getConsentStatus = () => new Promise((resolve, reject) => {
    window.googlefc = window.googlefc || {};
    window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];

    window.googlefc.callbackQueue.push({
        "CONSENT_DATA_READY":
        () => __tcfapi("addEventListener", 2.0, (tcdata, success) => {
            if (success && (tcdata.eventStatus === "useractioncomplete" || tcdata.eventStatus === "tcloaded")) {
                return resolve(tcdata.purpose.consents);
            }
            return reject();
        })
    });
});

// Prevent duplicate eventlisteners
const consentChecks = getConsentStatus();

export const noConsentMessage = "<p class=\"cookie-consent-message\">" +
    gettext("Your cookie settings do not allow us to show the contents of this block.") + " " +
    "<a href=\"#\" class=\"text-blue-700 hover:underline open-consent-settings\">" +
    gettext("Change cookie settings") + "</a></p>";

export function hasOptIn(category) {
    return consentChecks && consentChecks.then(checks => !!checks[consentTypes[category]]);
}

export function hasAllOptIns() {
    return Promise.all([
        "Select personalised ads",
        "Select basic ads",
        "Create a personalised ads profile",
        "Measure ad performance",
        "Apply market research to generate audience insights",
        "Develop and improve products",
        "Store and/or access information on a device",
    ].map(hasOptIn));
}

export function replaceContent(element, encoded) {
    // We don't use $.html() because the version of jQuery we use
    // does not evaluate the <script> tags in the right context.
    // See http://stackoverflow.com/questions/4079179/jquery-html-strips-out-script-tags
    element[0].innerHTML = window.atob(encoded);

    // <script> tags added with innerHTML are not executed.
    // We do that by taking them out and re-adding them.
    execScripts(element);
}

export function checkConsentContent(element) {
    hasAllOptIns().then(value => {
        for (const e of element.find("[data-consent-content]")) {
            if (value.every(val => val)) {
                const element = $(e);
                const encoded = element.data("consent-content");

                // Remove the data (and attribute) to prevent issues when
                // this function is called multiple times.
                element.removeData("consent-content");
                element.removeAttr("data-consent-content");

                replaceContent(element, encoded);
            } else {
                e.innerHTML = noConsentMessage;
            }
        }
    });
}

$(() => {
    $("body").on("click", ".open-consent-settings", e => {
        e.preventDefault();
        // eslint-disable-next-line no-undef
        googlefc.callbackQueue.push({"CONSENT_DATA_READY": () => googlefc.showRevocationMessage()});
    });
});

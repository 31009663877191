import "../../scss/widgets/license-plate-check.scss";
import WidgetBase from "./rainbow-base";

export class LicensePlateCheckWidget extends WidgetBase {

    constructor(element) {
        super(element);
        this.licensePlateUrl =
            "https://www.autoweek.nl/kentekencheck/" +
            "licensePlate?" +
            "utm_source=startpagina.nl&" +
            "utm_medium=cpc&" +
            "utm_campaign=startpagina_widget";
        this.on({
            "submit form": e => this.checkLicensePlate(e),
        });
    }

    checkLicensePlate(e) {
        e.preventDefault();
        const licensePlate = this.element.find("input[name='kenteken']").val();
        if (licensePlate) {
            window.open(this.licensePlateUrl.replace("licensePlate", licensePlate));
        }
    }
}

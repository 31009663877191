import {RADIO_SELECT_CONSTANTS} from "../config/radio-select-constants";
import ProfileAgent from "../widgets/profile";


const RadioSelectWidget = () => ({

    radioConstantsList: RADIO_SELECT_CONSTANTS,
    radioChoice: "",

    init() {
        this.$watch("$store.ProfileAgent.data", (data) => {
            this.radioChoice = data.radio_station;
        });
    },

    submitChoice() {
        ProfileAgent.set("radio_station", this.radioChoice);
        this.$el.dataset.trackEventLabel = this.radioChoice;
    }
});

export default RadioSelectWidget;

import $ from "jquery";

export function loadWidgets(widgetsToLoad, scope = null) {
    $(scope || "body").find("[data-widget]").each(function () {
        const element = $(this);
        const widgetName = element.data("widget");
        const widgetClass = widgetsToLoad[widgetName + "Widget"];
        if (widgetClass) {
            new widgetClass(element);
        }
    });
}

import $ from "jquery";

import gettext, {interpolate} from "./gettext";

export function formatInterval(seconds) {
    if (seconds < 60) {
        return seconds + "s";
    }

    let minutes = parseInt(seconds / 60, 10);
    seconds %= 60;

    if (minutes < 60) {
        return minutes + ":" + ("0" + seconds).substr(-2);
    }

    const hours = parseInt(minutes / 60, 10);
    minutes %= 60;

    return hours + ":" + ("0" + minutes).substr(-2) + ":" + ("0" + seconds).substr(-2);
}

function displayTimer() {
    const start = Date.now();
    const e = $(".preview-bar .render-time");

    function update() {
        const elapsed = Date.now() - start;
        e.text(interpolate(gettext("Rendered %s ago"), [formatInterval(parseInt(elapsed / 1000, 10))]));
    }

    setInterval(update, 1000);
}

$(function () {
    if (!$(".preview-bar").length) {
        return;
    }

    $(".preview-bar .close").on("click", e => {
        e.preventDefault();
        $(".preview-bar").hide();
    });

    displayTimer();
});

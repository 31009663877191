import "../../scss/widgets/weather-traffic.scss";
import WidgetBase from "./rainbow-base";
import {TrafficWidget} from "./traffic";
import {WeatherWidget} from "./weather";

/*
 The WeatherTraffic widget is a combination of the weather and traffic.
 It initializes the weather and traffic separately; but both use the same
 endpoint for retrieving/updating data.
 If a postal_code is given in the request it knows it needs information about
 the weather part; else it should return only traffic information.
 */
export class WeatherTrafficWidget extends WidgetBase {
    constructor(element) {
        super(element);

        new WeatherWidget(element);
        new TrafficWidget(element);
    }
}

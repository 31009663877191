import WidgetBase from "./base";
import ProfileAgent from "./profile";

export default class RainbowWidget extends WidgetBase {
    constructor(element) {
        super(element);
        if (element.attr("data-collapsed-by-default") === "True") {
            this.toggleMinimized(true);
        }
        this.on({
            "click .block-header": e => {
                const block = e.currentTarget.parentElement;
                if (block.getAttribute("data-can-be-collapsed") === "True") {
                    e.preventDefault();
                    this.toggleMinimized(!block.classList.contains("collapsed"));
                }
            },
            "collapse": () => this.collapse(),
            "expand": () => this.expand()
        });
    }

    /* Hide block content and change header icon to up.
     */
    collapse() {
        this.element.addClass("collapsed");
        const blockId = this.element.attr("id");
        this.element.data("id", blockId);
        this.isActive = false;
    }

    /* Display block content and change header icon to down.
     */
    expand() {
        this.element.removeClass("collapsed");
        const blockId = this.element.attr("id");
        this.element.data("id", blockId);
        this.isActive = true;
    }

    /* Update the block state in profile data.
     */
    toggleMinimized(minimized) {
        const blockId = this.element.attr("id");
        ProfileAgent.getData().then((data) => {
            if (minimized) {
                data.maximized_blocks = data.maximized_blocks.filter(block => block !== blockId);
                data.minimized_blocks.push(blockId);
            } else {
                data.minimized_blocks = data.minimized_blocks.filter(block => block !== blockId);
                data.maximized_blocks.push(blockId);
            }

            return ProfileAgent.set(data);
        });
    }
}

import $ from "jquery";
import _ from "lodash";

import UsageCounter from "./usage-counter";

export default class WidgetBase {
    constructor(element) {
        this.element = element;
        this.placementId = element.data("placementId");
        this.isInitialized = false;
        this.isActive = true;

        if (element.data("usageCounter")) {
            this.usageCounter = new UsageCounter(
                this.placementId,
                element.data("usageCounterVisits"),
                element.data("usageCounterDays")
            );
            this.usageCounter.countVisit();
            if (!this.usageCounter.isVisible()) {
                this.element.addClass("usage-fold");
                this.fold();
            }
            this.on({
                "click": () => {
                    this.element.removeClass("usage-fold");
                    this.usageCounter.markUsed();
                }
            });
        }
    }

    on(events) {
        for (const spec of Object.keys(events)) {
            const [, event, selector] = spec.match(/(\S+)(?:\s+(.*))?/);
            if (selector) {
                this.element.on(event, selector, events[spec]);
            } else {
                this.element.on(event, events[spec]);
            }
        }
    }

    getComponentUrl() {
        return `/api/widget/${this.placementId}/`;
    }

    getComponentData(params) {
        return WidgetBase.request(this.getComponentUrl(), params);
    }

    isPlacementTemporary() {
        return _.isString(this.placementId);
    }

    static request(options, params) {
        if (typeof options === "string") {
            options = {url: options};
        }
        if (!options.url) {
            throw new Error("A URL must be passed as a option to request");
        }

        return new Promise((resolve, reject) => {
            const defaults = {
                type: "GET",
                contentType: "application/json",
                data: params,
                success: data => resolve(data),
                error: reject
            };

            $.ajax(Object.assign({}, defaults, options));
        });
    }

    /* viewPort listener, when the element is visible in the viewport
     * it runs a callback function.
     *
     * This can be used to execute ajax calls or widget initialisation
     * only when the element is visible.
     *
     * It will only initialize the first time the element will be in
     * the viewport.
     */
    initInViewport(callback) {
        const viewportScrollHandler = () => {
            if (this.isInitialized) {
                return;
            }

            const elementTop = this.element.offset().top;
            const elementBottom = elementTop + this.element.outerHeight();
            const viewportTop = $(window).scrollTop();
            const viewportBottom = viewportTop + $(window).height();
            if (elementBottom > viewportTop && elementTop < viewportBottom) {
                $(window).off("resize scroll", viewportScrollHandler);
                this.isInitialized = true;
                callback();
            }
        };

        $(window).on("resize scroll", viewportScrollHandler);

        // Initial check on load
        viewportScrollHandler();
    }

    /* This utility handles the 'enter' key on an (input) element
     * and executes a callback function if the enter key is pressed.
     *
     * So this can be used in forms that don't have a form element.
     */
    elementKeyEnterCallback(e, callback) {
        if (e.which === 13) {
            callback();
        }
    }

    /* A widget might contain simple functionality to render / refresh html
     * This util will make a component request and get the full widget html
     * in respnse. This response contains the header too... So it will
     * give some issues when a widget html is fully replaced (collapsable
     * widget's and initial status).
     * The first argument on the function (elementSelector) can be used to
     * replace a specific content part.
     */
    reloadComponentHtml(elementSelector, params) {
        return new Promise((resolve, reject) => {
            if (!this.isActive) {
                return reject("Not refreshing, widget is minimized or folded");
            }
            if (this.isPlacementTemporary()) {
                return reject("Not refreshing, placement is temporary");
            }
            return $.get(this.getComponentUrl() + "html", params, data => {
                const widgetHtml = $(data),
                    replaceWithElement = widgetHtml.find(elementSelector);
                this.element.find(elementSelector).replaceWith(replaceWithElement);
                resolve(data);
            });
        });
    }
}

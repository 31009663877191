import {AccountButtonWidget} from "authwidgets";
import "authwidgets/dist/authwidgets.css";

import ProfileAgent from "../../rainbow/js/widgets/profile";
import getConfig from "../../umbrella/js/config";

const AccountButton = () => ({
    init() {
        const params = {
            profileUrl: getConfig("profileUrl"),
            apiUrl: getConfig("authserviceApiUrl"),
            loginUrl: `${getConfig("loginUrl")}#return=${window.location.href}`
        };

        this.accountButtonWidget = new AccountButtonWidget(this.$el, params);
        this.accountButtonWidget.onLogoutSucceeded = function () {
            this.accountButtonWidget.setAuthenticated(false);
            ProfileAgent.onLogout();
        }.bind(this);

        this.accountButtonWidget.inject();

        this.$watch("$store.ProfileAgent.data", (data) => {
            if (this.$store.ProfileAgent.isLoggedIn) {
                const displayName = `${data.first_name} ${data.last_name}`;
                this.accountButtonWidget.setAuthenticated(true, displayName);
            }
        });
    }
});

export default AccountButton;

import WidgetBase from "./rainbow-base";

export class TrafficWidget extends WidgetBase {
    constructor(element) {
        super(element);
        setInterval(() => {
            this.refreshTrafficDelay();
        }, 60000);
    }

    refreshTrafficDelay() {
        this.getComponentData().then(data => {
            this.element.find("[data-refresh='trafficjams_amount']").text(data.result.traffic.amount);
            this.element.find("[data-refresh='trafficjams_length']").text(data.result.traffic.length);
        });
    }
}

import $ from "jquery";

import ProfileAgent from "./widgets/profile";

$(function () {
    let previousBackground = "";

    // Automatically select dark background for users that had the high
    // contrast option enabled before.
    ProfileAgent.on("login", () => {
        const data = ProfileAgent.profile.data;
        if (data.high_contrast && !data.background) {
            ProfileAgent.set("background", "black");
        }
    });

    ProfileAgent.onChange("background", (e, background) => {
        if (previousBackground) {
            $("body").removeClass(`bg-${previousBackground}`);
        }
        if (background) {
            $("body").addClass(`bg-${background}`);
        }
        previousBackground = background;
    });
});

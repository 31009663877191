export default function getConfig(key, defaultValue) {
    if (window.appConfig === undefined) {
        throw Error("appConfig is missing");
    }

    if (window.appConfig[key] === undefined) {
        if (defaultValue === undefined) {
            throw Error(`Configuration parameter ${key} is not defined`);
        }
        return defaultValue;
    }

    return window.appConfig[key];
}

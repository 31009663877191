import "../../scss/widgets/daily-horoscope.scss";
import gettext from "../gettext";
import ProfileAgent from "./profile";
import WidgetBase from "./rainbow-base";

/*
The daily horoscope widget will show you horoscope by your date of birth.
Before you can see anything you must setup a valid date of birth in the profile.
This can be done as a logged in user or not logged in user.

It will retrieve data from an rss feed that returns all the zodiac signs for today.
*/

// Start date (month and day) for each of the zodiac signs. Steenbok (Capricorn)
// is listed twice because its duration starts in December and ends in January.
const zodiacSigns = [
    [1222, gettext("Capricorn")],
    [1122, gettext("Sagittarius")],
    [1023, gettext("Scorpio")],
    [923, gettext("Libra")],
    [823, gettext("Virgo")],
    [723, gettext("Leo")],
    [622, gettext("Cancer")],
    [521, gettext("Gemini")],
    [420, gettext("Taurus")],
    [321, gettext("Aries")],
    [220, gettext("Pisces")],
    [120, gettext("Aquarius")],
    [101, gettext("Capricorn")],
];

export class DailyHoroscopeWidget extends WidgetBase {

    constructor(element) {
        super(element);

        ProfileAgent.onChange("birth_date", (e, birthDate) => {
            if (birthDate) {
                const zodiacSign = this.getZodiacSignByDate(birthDate);
                this.showHoroscope(zodiacSign);
            } else {
                this.element.find(".zodiac-unknown").show();
            }
        });
    }

    getZodiacSignByDate(dateOfBirth) {
        const dateParts = dateOfBirth.split("-"),
            day = dateParts[2].length === 1 ? "0" + dateParts[2] : dateParts[2],
            mon = dateParts[1],
            num = parseInt(mon + day, 10);

        for (const [start, zodiac] of zodiacSigns) {
            if (num >= start) {
                return zodiac;
            }
        }

        return null; // This should not happen
    }

    showHoroscope(zodiacSign) {
        this.element.find(".zodiac-" + zodiacSign.toLowerCase()).removeClass("zodiac-hidden");
    }
}

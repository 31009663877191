/* eslint-disable startpagina/sort-imports */
import "../../scss/_forms.scss";
import "../../scss/widgets/newsletter.scss";
import NewsletterwidgetController from "../newsletterwidget-controller";
import RainbowBase from "./rainbow-base";

export class NewsletterWidget extends RainbowBase {
    constructor(element) {
        super(element);
        this.controller = new NewsletterwidgetController(element);
    }
}

import $ from "jquery";

import "../../scss/widgets/radio.scss";
import {hasAllOptIns, noConsentMessage} from "../consent";
import getUrlParam from "../url-params";
import {loadBanner} from "./../banners";
import WidgetBase from "./rainbow-base";

export class RadioWidget extends WidgetBase {
    constructor(element) {
        super(element);
        this.initInViewport(() => {
            this.updateRadioUrl();
        });
        window.addEventListener("message", (event) => {
            this.refreshAds(event);
        });
    }

    updateRadioUrl() {
        const iframe = this.element.find("iframe");
        if (hasAllOptIns()) {
            let url = iframe.data("src");
            const params = url.split("/").slice(-1)[0],
                stationUrl = this.getRadioUrl();
            url = url.replace(params, stationUrl);
            url += "/" + params;
            iframe.attr("src", url);
        } else {
            this.element.find(".block-content").height(iframe.height()).html(noConsentMessage);
        }
    }

    getRadioUrl() {
        const urls = {
            "radio 1": "radio-1.html",
            "radio 2": "radio-2.html",
            "radio-3fm": "radio-3fm.html",
            "radio 538": "radio-538.html",
            "100%nl": "100p-nl.html",
            "sky radio": "sky-radio.html",
            "q-music": "q-music.html",
            "radio veronica": "radio-veronica.html",
            "radio 4": "radio-4.html",
            "radio 5": "radio-5.html",
            "radio 6": "radio-6.html",
            "bnr nieuwsradio": "bnr-nieuwsradio.html",
            "arrow classic rock": "arrow-classic-rock.html",
            "caz!": "arrow-caz.html",
            "classic fm": "classic-fm.html",
            "funx": "funx-nl.html",
            "pinguin radio": "pinguin-radio.html",
            "radio 10 gold": "radio-10-gold.html",
            "slam fm": "slam-fm.html",
            "radio decibel": "radio-decibel.html",
            "arrow jazz fm": "arrow-jazz-fm.html",
            "sublime-fm": "sublime-fm.html",
        };
        const station = getUrlParam("station");
        return urls[station] ? urls[station] : "";
    }

    refreshAds(event) {
        if (event.data === "refreshAds") {
            let banners = $(".block.banner").toArray();
            banners = banners.concat($(".skyscraper").toArray());
            for (let i = 0; i < banners.length; i++) {
                this.reloadBanner(banners[i]);
            }
        }
    }

    reloadBanner(container) {
        const bannerType = $(container).attr("id");
        $(container).empty();

        loadBanner(container, bannerType);
    }

}

import $ from "jquery";

import "../../scss/widgets/news-rss.scss";
import WidgetBase from "./rainbow-base";

/*
 * The NewsRssFeed widget shows the latest news items from an rss feed.
 *
 * The data is stored in the feeds table and preprocessed/cached by a backend
 * process.
 *
 * Each widget refreshes it's HTML with the items every 60 seconds.
 */
export class NewsRssWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.preloadImages(this.feedImages());
        this.init();

        setInterval(() => {
            if (this.isActive) {
                this.reloadComponentHtml("ul").then(() => {
                    this.init();
                });
            }
        }, 60000);
    }

    feedImages() {
        const images = [];

        $(".news-rss-links", this.element).each((index, element) => {
            images.push(
                $(element).data("image")
            );
        });

        return images;
    }

    init() {
        this.imageInit = $(".news-rss-links:first", this.element).data("image");
        $(".feed-image", this.element).css("background", `url(${this.imageInit}) top left / cover no-repeat`);

        this.on({
            "mouseenter .news-rss-links": element => this.setImageActive(element)
        });
    }

    /*
     * https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video
     * https://perishablepress.com/3-ways-preload-images-css-javascript-ajax/
     */
    preloadImages(images) {
        setTimeout(() => {
            images.forEach(image => {
                new Image().src = image;
            });
        }, 1000);
    }

    setImageActive(element) {
        const imageActive = $(element.currentTarget).data("image");
        $(".feed-image", this.element).css("background", `url(${imageActive}) top left / cover no-repeat`);
    }
}

import Alpine from "alpinejs";

import breakpoints from "../breakpoints";
import {FOUR_COL_GRID} from "../config/grid-layouts";

Alpine.store("Breakpoint", {
    data: {},

    init() {
        // Skip initialisation if grid layout isn't used
        if (this.checkLayout()) {
            this.update();
            addEventListener("resize", () => {
                !this.isCurrentTemplate() && this.update();
            });
        }
    },

    checkLayout() {
        return !!document.querySelector(".grid-area-col-1 .block");
    },

    getCurrentContainer() {
        return document.querySelector(".grid-area-col-1 .block").parentNode.parentNode;
    },

    getBreakpoint() {
        return Object.keys(FOUR_COL_GRID).find(viewport => {
            const viewportCheck = window.matchMedia(`(min-width: ${breakpoints[viewport]})`);
            return viewportCheck.matches === true;
        }) || "breakpointNone";
    },

    isCurrentTemplate() {
        const breakpoint = this.getBreakpoint();
        const {template} = FOUR_COL_GRID[breakpoint];
        const currTemplate = this.getCurrentContainer();
        return !![...currTemplate.classList].find(className => `.${className}` === template);
    },

    update() {
        const container = this.getCurrentContainer();
        const breakpoint = this.getBreakpoint();

        this.data = {
            container: container,
            breakpoint: breakpoint,
            newContainer: FOUR_COL_GRID[breakpoint].template,
            count: FOUR_COL_GRID[breakpoint].count
        };
    }
});

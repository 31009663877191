import Alpine from "alpinejs";

Alpine.store("PersonalLinks", {
    isEditing: false,
    selectedLink: null,

    toggleEditMode(isEditing) {
        this.isEditing = isEditing;
    },

    addToPersonalLinks(linkElem) {
        this.selectedLink = linkElem;
    },
});

/* eslint-disable max-len */
import getConfig from "../../umbrella/js/config";
import Utils from "./utilities";
import FormValidator from "./validation/form-validator";
import ProfileAgent from "./widgets/profile";

export default class NewsletterwidgetController {
    constructor(element) {
        this.viewElement = element[0];
        this.genericErrorMessage = "Er is iets fout gegaan tijdens het aanmelden voor de nieuwsbrief. Probeer het later opnieuw.";

        this.initializeForm();

        ProfileAgent.on("login", () => {
            const data = ProfileAgent.profile.data;
            if (data.newsletter) {
                this.showExistingSubscriptionView();
            } else if (!Utils.isNullOrUndefined(data.email)) {
                this.viewElement.querySelector("#email").value = data.email;
                this.viewElement.querySelector("#first_name").value = data.first_name;
                this.viewElement.querySelector("#last_name").value = data.last_name;
            }
        });
    }

    initializeForm() {
        this.form = this.viewElement.querySelector("#newsletter-form");
        this.submitButton = this.form.querySelector("button[type='submit']");
        this.fieldIds = ["email", "first_name", "last_name", "GESLACHTm"];
        this.formValidator = new FormValidator(this.viewElement, this.fieldIds);

        this.submitButton.addEventListener("click", (event) => {
            event.preventDefault();
            this.submit();
        });
    }

    submit() {
        if (this.formValidator.validate()) {
            const formDataAsJson = Utils.convertFormDatatoJson(this.form);

            const fetchConfig = {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                referrerPolicy: "no-referrer-when-downgrade",
                body: formDataAsJson,
            };

            Utils.setSubmitButtonState(this.submitButton, true);
            fetch(getConfig("newsletterApiUrl"), fetchConfig)
                .then(response => {
                    if (response.status === 201) {
                        // Subscription succeeded - show confirmation-view (see newsletter.html)
                        this.showConfirmationView();
                    } else if (response.status === 400) {
                        // Subscription failed - validation errors on server
                        response.json().then(data => {
                            this.processValidationErrors(data);
                            Utils.setSubmitButtonState(this.submitButton, false);
                        });
                    } else {
                        // Subscription failed - rseponse-status other than 201 or 400
                        this.formValidator.setGenericStatus(this.genericErrorMessage);
                        Utils.setSubmitButtonState(this.submitButton, false);
                    }
                })
                .catch(error => {
                    this.formValidator.setGenericStatus(this.genericErrorMessage);
                    Utils.setSubmitButtonState(this.submitButton, false);
                    console.error("Failure during fetch with error:");
                    console.error(error);
                });
        }
    }

    processValidationErrors(data) {
        const fieldNames = Object.keys(data);
        if (fieldNames.length > 0) {
            fieldNames.forEach((fieldName) => {
                if (fieldName !== "traceback") {
                    const fieldMessages = data[fieldName];
                    if (fieldName === "detail") {
                        this.formValidator.setGenericStatus(fieldMessages[0]);
                    } else {
                        this.formValidator.setStatus(fieldName, fieldMessages[0], "error");
                    }
                }
            });

        } else {
            this.formValidator.setGenericStatus(this.genericErrorMessage);
        }
    }

    showConfirmationView() {
        this.viewElement.querySelector(".subscription-added-container").classList.remove("hidden");
        this.viewElement.querySelector(".form-container").classList.add("hidden");
    }

    showExistingSubscriptionView() {
        this.viewElement.querySelector(".subscription-exists-container").classList.remove("hidden");
        this.viewElement.querySelector(".form-container").classList.add("hidden");
    }
}

const Pagination = () => ({
    totalPages: 1,
    visiblePages: [1],

    init() {
        this.$watch("$store.ProfileAgent.data", (data) => {
            this.links = data.links;
            this.setTotalPages(this.links, this.linksPerPage);
        });
        this.$watch("$store.ProfileAgent.currentPage", (idx) => {
            this.currentPage = idx;
        });
    },

    setTotalPages(items, groupSize) {
        if (items) {
            this.totalPages = Math.ceil(items.length / groupSize);
        }
    },
});

export default Pagination;

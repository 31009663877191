export function parse(url) {
    const urlParams = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
        urlParams[key] = decodeURIComponent(value.replace(/\+/g, " "));
    });
    return urlParams;
}

const locationParams = parse(window.location.search);

export default function getUrlParam(key, fallback) {
    return locationParams[key] || fallback;
}

import $ from "jquery";

import "../../scss/widgets/selected-for-you.scss";
import {hasOptIn} from "../consent";
import {BlockWidget} from "./block";

export class SelectedForYouWidget extends BlockWidget {
    constructor(element) {
        super(element);

        if (!hasOptIn("Create a personalised ads profile")) {
            // No cookie consent has been give, do not show AdSense.
            return;
        }

        const segments = localStorage.kxsegs || "";
        this.getComponentData({segments: segments}).then(data => {
            const rows = $(data.result, "li");
            rows.find("a").addClass("dynamic");
            rows.appendTo(this.element.find("ul"));
        });
    }
}

let breakpoints;
try {
    // This fails when running the tests through Mocha.
    // eslint-disable-next-line no-restricted-globals
    breakpoints = require("!!sass-variables-loader!../../umbrella/scss/_partials/_breakpoints.scss");
} catch (e) {
    breakpoints = {};
}

export default breakpoints;

import LocationBasedWidget from "./locationbasedwidget";

export class OpeningHoursWidget extends LocationBasedWidget {
    constructor(element) {
        super(element);

        this.form = element.find("form");
        this.locationField = element.find("input[name='location']");
        this.queryField = element.find(".opening-hours-query");
        this.queryField.on("keyup", () => {
            this.form.data("trackEventLabel", this.queryField.val());
        });
    }

    displayLocation() {
        super.displayLocation();
        this.locationField.val(this.currentLocation.location);
    }
}

const TalpaVideoFeed = () => ({
    widgetId: null,
    currentVideo: "",

    init() {},

    toggleIframeLoader(iframe, loader, playlist) {
        iframe.classList.toggle("hidden");
        loader.classList.toggle("hidden");
        if (iframe.offsetHeight !== 0) {
            playlist.setAttribute("style", `height:${iframe.offsetHeight}px`);
        }
    },

    changeEmbed(url) {
        // Wait for instantiation of component
        this.$nextTick(() => {
            // x-ref will become undefined due to <template> so we use DOM selectors instead
            const loader = document.querySelector("#videoFeedLoader");
            const iframe = document.querySelector("#videoFeedIframe");
            const playlist = document.querySelector("#videoFeedPlaylist");

            this.toggleIframeLoader(iframe, loader);
            this.currentVideo = `${url}&token=kompas-sp-web`;
            iframe.addEventListener("load", () => {

                this.toggleIframeLoader(iframe, loader, playlist);
            }, {passive: true, once: true});
        });
    },

    getThumbnail(url, height = 60) {
        return `https://cldnr.talpa.network/talpa-network/image/fetch/ar_16:9,c_fill,f_auto,g_face:auto,h_${height},w_auto/${url}`;
    },

    setBackground(thumbnail) {
        return `background-image: url(${this.getThumbnail(thumbnail, 110)})`;
    }

});

export default TalpaVideoFeed;

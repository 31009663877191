import Alpine from "alpinejs";

import AccountButton from "../../profile/js/account-button";
import ChildLockWidget from "./components/child-lock-widget";
import FeedWidget from "./components/feed-widget";
import GridLayout from "./components/grid-layout";
import IframeWidget from "./components/iframe-widget";
import LabeledLinksWidget from "./components/labeled-links-widget";
import NewsletterWithImageAndUsp from "./components/newsletter-with-image-and-usp";
import Pagination from "./components/pagination";
import PersonalLinks from "./components/personal-links";
import PollWidget from "./components/poll-widget";
import PromoBarWidget from "./components/promo-bar-widget";
import RadioSelectWidget from "./components/radio-select-widget";
import ScrollTop from "./components/scroll-top";
import TalpaVideoFeed from "./components/talpa-videofeed";
import TrafficWidget from "./components/traffic-widget";
import WidgetBase from "./components/widget-base";
import "./stores/breakpoint";
import "./stores/overlay";
import "./stores/personal-links";
import "./stores/profile-agent";

Alpine.data("AccountButton", AccountButton);
Alpine.data("ChildLockWidget", ChildLockWidget);
Alpine.data("PollWidget", PollWidget);
Alpine.data("PromoBarWidget", PromoBarWidget);
Alpine.data("FeedWidget", FeedWidget);
Alpine.data("IframeWidget", IframeWidget);
Alpine.data("GridLayout", GridLayout);
Alpine.data("ScrollTop", ScrollTop);
Alpine.data("WidgetBase", WidgetBase);
Alpine.data("PersonalLinks", PersonalLinks);
Alpine.data("Pagination", Pagination);
Alpine.data("TalpaVideoFeed", TalpaVideoFeed);
Alpine.data("TrafficWidget", TrafficWidget);
Alpine.data("RadioSelectWidget", RadioSelectWidget);
Alpine.data("LabeledLinksWidget", LabeledLinksWidget);
Alpine.data("NewsletterWithImageAndUsp", NewsletterWithImageAndUsp);

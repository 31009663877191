import $ from "jquery";
import _ from "lodash";

import getConfig from "./../../../umbrella/js/config";

const maxCommercialLinksAge = 60 * 60 * 24,
    profileUrl = getConfig("profileApiUrl") + "/profile";

export class BaseProfile {
    constructor() {
        this.data = {};

        // both local and remote profiles will check the local storage,
        // but only the local profile will use it for storing the data
        if (localStorage.profile_json) {
            try {
                this.data = JSON.parse(localStorage.profile_json);

                if (this.migrateLocations()) {
                    this.saveLocalStorage();
                }
            } catch (e) {
                console.warning("Unable to load the local profile", e);
                this.clearLocalStorage();
            }
        }
    }

    /**
     * Attempt to migrate the location data
     * Returns true is there were updates, false otherwise
     */
    migrateLocations() {
        if (!(this.data && this.data.locations)) {
            return false;
        }

        return this.data.locations.reduce((wasUpdated, location) => {
            if (location.lng) {
                location.lon = location.lng;
                delete location.lng;
                return true;
            }

            return wasUpdated;
        }, false);
    }

    /**
     * After profile is retrieved from localStorage try to update the links
     * with the latest link information
     */
    processProfile(profile) {
        return new Promise((resolve) => {
            // If no links, skip processing
            if (!profile.links || profile.links.length === 0) {
                resolve();
                return;
            }

            // Create list of commercial link id
            const commercialLinkIds = [];
            for (const link of profile.links) {
                if (this.isCommercialLink(link)) {
                    // If this link has a DMS ID but no Tracking ID, convert it (see also SPR-1410).
                    if (link.dms_id && !link.tracking_id) {
                        link.tracking_id = 10000000 + link.dms_id;
                    }
                    commercialLinkIds.push(link.tracking_id);
                }
            }

            // Try to update links by links on current page.
            const changed = this.processCommercialLinksOnCurrentPage(profile, commercialLinkIds);

            // Only process if profile hasn't been checked for a while.
            if (commercialLinkIds.length === 0 || !this.shouldRefreshCommercialLinks()) {
                if (changed) {
                    this.storeData(profile);
                }

                resolve();
                return;
            }

            // Fill in the blanks by retrieving data from the api
            this.processCommercialLinksFromApi(profile, commercialLinkIds).then(() => {
                // Make sure to store the latest information.
                this.storeData(profile);

                resolve();
            });
        });
    }

    /**
     * Check if a specific link object is commercial.
     */
    isCommercialLink(link) {
        return !!link.dms_id || !!link.tracking_id;
    }

    /**
     * Processes the id's in commercialLinkIds and update the profile
     * links wih the latest data that we have on the page.
     *
     * When there's a match it will remove the id from commercialLinkIds
     */
    processCommercialLinksOnCurrentPage(profile, commercialLinkIds) {
        const commercialLinkIdsOnPage = {};
        $(".link-block a[data-track-tracking-id]").each((value, element) => {
            const linkElement = $(element);
            const linkUrl = linkElement.data("href") || linkElement.attr("href");
            const trackingId = linkElement.data("trackTrackingId");

            commercialLinkIdsOnPage[trackingId] = {
                zlostat_id: linkElement.data("trackZlostatId"),
                tracking_id: trackingId,
                url: linkUrl
            };
        });

        let changed = false;
        for (let i = commercialLinkIds.length - 1; i >= 0; --i) {
            const link = commercialLinkIdsOnPage[commercialLinkIds[i]];
            if (link) {
                this.updateProfileWithCommercialLink(profile, link);
                commercialLinkIds.splice(i, 1);
                changed = true;
            }
        }
        return changed;
    }

    /**
     * Retrieves link information from the api by the commercialLinkIds
     * and updates the local profile links with the latest link
     * information.
     */
    processCommercialLinksFromApi(profile, commercialLinkIds) {
        return new Promise((resolve) => {
            // We want to estimate by how many users this functionality is
            // is used, so we generate a unique ID for this profile to add
            // to the request.
            if (!profile.local_user_id) {
                profile.local_user_id = "";
                while (profile.local_user_id.length < 20) {
                    profile.local_user_id += Math.random().toString(36).substr(2);
                }
            }

            const defaults = {
                type: "POST",
                contentType: "application/json",
                url: "/api/profile/links",
                data: JSON.stringify({
                    user_id: profile.local_user_id,
                    links: commercialLinkIds,
                }),
                success: links => {
                    // Update links in profile.
                    for (const link of links) {
                        this.updateProfileWithCommercialLink(profile, link);
                    }

                    resolve();
                }
            };
            $.ajax(defaults);
        });
    }

    /**
     * Detect when the profile was last updated; and check if it's older
     * then `maxCommercialLinksAge`
     */
    shouldRefreshCommercialLinks() {
        return (Date.now() - localStorage.profile_updated) / 1000 > maxCommercialLinksAge;
    }

    /**
     *  Checks if the profile has a specific link stored that matches the
     *  commercial link.
     *  If there is a match the link will be updated with the latest data
     *  we know of.
     */
    updateProfileWithCommercialLink(profile, link) {
        for (let i = 0; i < profile.links.length; i++) {
            if (profile.links[i].tracking_id === link.tracking_id) {
                profile.links[i].url = link.url;
                if (link.zlostat_id) {
                    profile.links[i].zlostat_id = link.zlostat_id;
                }
            }
        }
    }

    saveLocalStorage() {
        localStorage.profile_updated = Date.now();
        localStorage.profile_json = JSON.stringify(this.data);
    }

    clearLocalStorage() {
        delete localStorage.profile_json;
        delete localStorage.profile_updated;
    }

    update(data) {
        for (const key of Object.keys(data)) {
            if (key === "links" || key === "locations") {
                for (const item of data[key]) {
                    if (!item.id) {
                        item.id = Math.random() * 1000000 | 0;
                    }
                }
            }
            this.data[key] = data[key];
        }
        return Promise.resolve();
    }

    storeData(data) {
        this.data = data;
    }

    refresh() {
        return this.processProfile(this.data);
    }
}

export class LocalProfile extends BaseProfile {
    update(data) {
        const ret = super.update(data);
        this.saveLocalStorage();

        return ret;
    }

    storeData(data) {
        super.storeData(data);
        this.saveLocalStorage();
    }
}

export class RemoteAccountProfile extends BaseProfile {
    constructor() {
        super();
    }

    request(options) {
        return new Promise((resolve, reject) => {
            const defaults = {
                type: "GET",
                url: profileUrl,
                xhrFields: {
                    withCredentials: true
                },
                success: data => resolve(data),
                error: reject
            };

            $.ajax($.extend({}, defaults, options || {}));
        });
    }

    update(data) {
        for (const key of Object.keys(data)) {
            this.data[key] = data[key];
        }

        return new Promise((resolve, reject) => {
            this.request({
                type: "PATCH",
                contentType: "application/json",
                headers: {
                    "X-CSRFToken": this.data.csrf_token
                },
                data: JSON.stringify(data)
            }).then(data => {
                this.storeData(data);
                resolve();
            }, reject);
        });
    }

    /**
     * Refreshes profile information when accountInfo is triggered by the
     * account event.
     *
     * It processes the profile first to see if commercial links can be
     * updated by looking at the page content; and possible update by
     * retrieving link information from the backend.
     *
     * The processProfile always resolves; and if everything is up-to-date
     * it will end the refresh.
     *
     * If the latest store date of the profile in the localstorage exceeded
     * the 'maxProfileAge' it will make a new profile request and retrieves
     * all latest profile information from the backend.
     *
     * For an existing user it will update the localstorage with the data
     * retrieved from the backend.
     *
     * In the case the user is there for the first time it will send the
     * current data in the localstorage to the backend.
     */
    refresh() {
        return new Promise((resolve, reject) => {
            this.processProfile(this.data).then(() => {
                this.request().then(
                    data => {
                        if (!data.uid) {
                            reject();
                            return;
                        }

                        if (data.imported) {
                            this.storeData(data);
                            this.clearLocalStorage();
                            resolve();
                            return;
                        }

                        // New user (first login to Startpagina)
                        const profileData = _.pickBy(this.data, (value, key) => data[key] !== value);
                        profileData.imported = true;

                        if (profileData.links) {
                            for (const link of profileData.links) {
                                delete link.id;
                            }
                        }
                        if (profileData.locations) {
                            for (const location of profileData.locations) {
                                delete location.id;
                            }
                        }

                        this.request({
                            type: "PATCH",
                            contentType: "application/json",
                            headers: {
                                "X-CSRFToken": this.data.csrf_token
                            },
                            data: JSON.stringify(profileData)
                        }).then(
                            data => {
                                this.storeData(data);
                                this.clearLocalStorage();
                                resolve();
                            },
                            reject
                        );
                    },
                    reject
                );
            });
        });
    }
}

export default class ProfileAgent {
    constructor() {
        throw new TypeError("ProfileAgent is a static class");
    }

    static isLoggedIn() {
        return this.profile instanceof RemoteAccountProfile;
    }

    static onLogout() {
        this.profile.data = {};
        this.profile.clearLocalStorage();
        this.profile = new LocalProfile();
        this.trigger("logout");
        window.location.replace(window.location.origin);
    }

    static onInit() {
        this.trigger("login");
        this.trigger("change", this.profile.data);
        for (const key of Object.keys(this.registeredFields)) {
            this.triggerChange(key);
        }
    }

    static init() {
        const remoteProfile = new RemoteAccountProfile();

        return remoteProfile.refresh().then(() => {
            this.profile = remoteProfile;
            this.onInit();
        }).catch(() => {
            this.profile = new LocalProfile();
            this.profile.refresh().then(() => {
                this.onInit();
            });
        });
    }

    static goToLocation() {
        window.location.href = getConfig("profileUrl") + "/locatie";
    }

    static getProfilePrivacyUrl() {
        return getConfig("profileUrl") + "/privacy";
    }

    static getData() {
        return new Promise((resolve, reject) => {
            this.accountInfo.then(() => {
                this.profile.refresh().then(
                    () => {
                        const data = this.profile.data;

                        data.minimized_blocks = data.minimized_blocks ? [...data.minimized_blocks] : [];
                        data.maximized_blocks = data.maximized_blocks ? [...data.maximized_blocks] : [];

                        resolve(data);
                    },
                    () => {
                        reject("Unable to refresh profile.");
                    }
                );
            });
        });

    }

    static set(key, value) {
        const data = typeof key === "object" ? key : {[key]: value};

        return new Promise((resolve, reject) => {
            this.accountInfo.then(() => {
                this.profile.update(data).then(() => {
                    this.trigger("change", this.profile.data);
                    for (const key of Object.keys(data)) {
                        this.triggerChange(key);
                    }
                    resolve();
                }, reject);
            });
        });
    }

    static update(key, defaultValue, callback) {
        // When called with only two arguments, the second argument is the
        // callback function.
        if (callback === undefined) {
            callback = defaultValue;
            defaultValue = undefined;
        }

        return this.getData().then(data => {
            const currentValue = data[key] || defaultValue;
            const newValue = callback(currentValue);
            if (newValue !== undefined) {
                return this.set(key, newValue);
            }
            return true;
        });
    }

    static triggerChange(key) {
        this.trigger("change_" + key, [this.profile.data[key]]);
    }

    static trigger(eventType, data) {
        const event = $.Event(eventType);
        this.events.triggerHandler(event, data);
        return event;
    }

    static on(event, callback) {
        this.events.on(event, callback);
    }

    static onChange(key, callback) {
        if (key === "*") {
            this.events.on("change", callback);
        } else {
            this.registeredFields[key] = true;
            this.events.on("change_" + key, callback);
        }
    }
}

ProfileAgent.events = $({});
ProfileAgent.registeredFields = {};

$(() => {
    // Only run init() if it isn't ran from AlpineJS already
    if (!ProfileAgent.accountInfo) {
        ProfileAgent.accountInfo = ProfileAgent.init();
    }
});

import $ from "jquery";

import ProfileAgent from "./widgets/profile";


/**
 * Return an item from each iterable in turn
 * roundRobin([[1, 2], [3], [4, 5]]) => [1, 3, 4, 2, 5]
 * @param iterables
 * @returns {IterableIterator<*>}
 */
export function *roundRobin(iterables) {
    const iterators = [];
    for (const it of iterables) {
        iterators.push(it[Symbol.iterator]());
    }
    let remaining = iterators.length;
    while (remaining) {
        for (let i = 0; i < remaining;) {
            const v = iterators[i].next();
            if (v.done) {
                iterators.splice(i, 1);
                remaining -= 1;
            } else {
                yield v.value;
                i += 1;
            }
        }
    }
}

/**
 * Return an endless repeating cycle of values
 * cycle([1, 2, 3]) => [1, 2, 3, 1, 2, 3, 1, 2, 3, ...]
 * @param values
 * @returns {IterableIterator<*>}
 */
export function *cycle(values) {
    while (true) {
        yield* values;
    }
}

/* Alternate adding blocks to a given number of section arrays
 *
 * @param block
 * @param columns
 */
export class BlockToSectionAdder {
    constructor(numberOfSections) {
        this.sections = [];
        while (numberOfSections--) {
            this.sections.push([]);
        }
        this.section = cycle(this.sections);
    }

    addBlock(block) {
        // TODO: after SPR-3652 -> #app shouldn't be needed to scope Tailwind anymore
        // Refactored nodes use <section>
        // This adds back the <div id="app"> around the node removed by responsiveness.js and needed for  Tailwind styling to be applied
        if (block.nodeName === "SECTION") {
            const wrapper = document.createElement("div");
            wrapper.setAttribute("id", "app");

            block.parentNode.insertBefore(wrapper, block);
            wrapper.appendChild(block);

            block = wrapper;
        }
        this.section.next().value.push(block);
    }

    getSections() {
        return this.sections;
    }
}

/* Create a block iterator given a div containing sections with blocks
 *
 * @param blockContainer
 * @returns {*}
 */
export function getBlockIterator(blockContainer) {
    const sections = $(".section", blockContainer).not(".section-wide");
    const blocksPerSection = sections.map(function () {
        // Only select direct siblings with class .block
        return $(".block", this).siblings();
    });
    return roundRobin(blocksPerSection);
}


/* Get minimized blocks for user,
 * Display users minimized blocks folded on the bottom of the page
 */
export function minimizeBlocks() {
    ProfileAgent.onChange("minimized_blocks", (e, minimizedBlocks) => {
        if (!minimizedBlocks) {
            return;
        }

        for (const blockId of minimizedBlocks) {
            $("#" + blockId).trigger("collapse");
        }
    });

    ProfileAgent.onChange("maximized_blocks", (e, maximizedBlocks) => {
        if (!maximizedBlocks) {
            return;
        }

        for (const blockId of maximizedBlocks) {
            $("#" + blockId).trigger("expand");
        }
    });
}

/* Check and return position when page scrolling
 * Manage show/hide classes of header
 */
export function checkScrollTopNavigation(lastScrollTop) {
    const delta = 5,
        header = $("body > .header"),
        scrollTop = window.pageYOffset,
        headerHeight = header[0].clientHeight,
        windowHeight = $(window).height(),
        documentHeight = $(document).height(),
        consentBarMobile = document.getElementById("sp_message_iframe_477910");

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - scrollTop) <= delta) {
        return;
    }

    if (scrollTop > headerHeight * 8 && !header.hasClass("is-sticky")) {
        header.addClass("is-sticky hide");
    } else if (scrollTop < headerHeight * 8) {
        header.removeClass("is-sticky");
    }

    if (scrollTop > lastScrollTop && scrollTop > headerHeight * 1.5) {
        // Scroll Down
        if (!header.hasClass("hide")) {
            header.addClass("is-invisible");
        }
        header.removeClass("is-visible");
    } else if (
        scrollTop + windowHeight < documentHeight && consentBarMobile && window.getComputedStyle(
            consentBarMobile).visibility === "hidden"
    ) {
        header.addClass("is-visible");
        header.removeClass("is-invisible hide");
    }
}

export function resetTopNavigation() {
    $("body > .header").removeClass("hide is-invisible is-visible is-sticky");
}

/*
 * Based on https://github.com/eevee/eev.ee/blob/fde34e57adecb960e2d10ead0e69ebdc874279a6/theme/static/PARTYMODE/partymode.js
 * Copyright ©2014, Lexy Munroe a.k.a. Eevee
 *
 * Permission to use, copy, modify, and/or distribute this software for any
 * purpose with or without fee is hereby granted, provided that the above
 * copyright notice and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES
 * WITH REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY
 * AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
 * LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE
 * OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
 * PERFORMANCE OF THIS SOFTWARE.
 */
import $ from "jquery";

import "../scss/party-mode.scss";
import registerCheatCode from "./cheat-codes";

// Number of kilopixels dedicated to each snowflake
const FLAKE_SPARSITY = 15;

function createFlake() {
    const flake = $("<div />");
    flake.addClass("party-mode--flake");
    flake.text("✨");

    // Randomize a bit
    flake.css({
        animationDelay: "-" + String(Math.random() * 4) + "s",
        animationDuration: String(Math.random() * 3 + 4) + "s",
        left: String(Math.random() * 120 - 10) + "%",
        fontSize: String(Math.random() * 50 + 20) + "px",
    });
    return flake;
}

function enablePartyMode() {
    // Create the container and fill it with some elements
    const container = $("<div />").addClass("party-mode--container");

    const area = document.documentElement.clientWidth * document.documentElement.clientHeight;
    const numFlakes = area / FLAKE_SPARSITY / 1000;

    for (let i = 0; i < numFlakes; i++) {
        container.append(createFlake());
    }

    // And done!  No actual JavaScript required to keep it running.
    container.appendTo($("body"));
}

registerCheatCode("$party", enablePartyMode);

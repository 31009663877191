// https://www.agnoplay.com/cp/docs/implementation/

import $ from "jquery";

import getConfig from "../../umbrella/js/config";

let loadPlayerScriptPromise = null;

function loadPlayerScript() {
    if (loadPlayerScriptPromise) {
        return loadPlayerScriptPromise;
    }

    loadPlayerScriptPromise = new Promise((resolve, reject) => {
        $.ajax({
            url: getConfig("agnoplayScriptUrl"),
            type: "GET",
            dataType: "script",
            cache: true,
            global: false,
            "throws": true,
            success: () => {
                resolve();
            },
            error: (xhr, status) => {
                console.error("Failed to load the Agnoplay script", status);
                reject();
            }
        });
    });

    return loadPlayerScriptPromise;
}

function initializeAgnoPlayer(element, config) {
    const fullConfig = Object.assign({}, config, {
        brand: "startpagina",
        url: window.location.href,
        license_key: getConfig("agnoplayLicenseKey"),
    });
    window.AGNO.insertPlayer(fullConfig, element);
}

export function loadPlayer(element, config) {
    return loadPlayerScript().then(() => {
        initializeAgnoPlayer(element, config);
    });
}

export function destroyPlayers() {
    if (window.AGNO) {
        window.AGNO.disposeAll();
    }
}

/*global UET:false */
/* SEA Tracking Object
 *
 * Copyright 2015 Serge Cornelissen
 * Version: 2.0
 */
class SEATracker {
    constructor(consent, debug) {
        this.profile = "";
        this.gclid = null;
        this.bing = null;
        this.session = null;
        this.consent = consent;
        this.debug = !!debug;

        if (this.consent) {
            this.Adwords();
            this.Bing();
            this.sessionStart();
        }
    }

    // Get the URL parameter 'gclid' and store it in an 'is_adwords' cookie (90 days).
    // Otherwise if gclid is not present but there is a cookie, use this value.
    Adwords() {
        const gclid = this.getParam("gclid");
        const cookie = this.getCookie("is_adwords");

        if (gclid) {
            const gclsrc = this.getParam("gclsrc");
            if (!gclsrc || gclsrc.indexOf("aw") !== -1) {
                this.setCookie("is_adwords", gclid, 90 * 24 * 60);
                this.gclid = gclid;
                this.session = "adwords";
            }
        } else if (cookie) {
            this.gclid = cookie;
        }
    }

    // Check the URL parameter utm_source equals BING and store the value of
    // the query parameter 'gclid' in a 'is_bing' cookie (90 days).
    // Otherwise if parameter is not present, but there is a cookie from the
    // past, use this value.
    Bing() {
        const param = this.getParam("utm_source");
        const cookie = this.getCookie("is_bing");
        const sessionCookie = this.getCookie("_sea");

        if (param === "BING" && sessionCookie !== "bing") {
            const bingID = this.getParam("gclid");
            this.setCookie("is_bing", bingID, 90 * 24 * 60); // 90 days
            this.bing = bingID;
            this.session = "bing";
        } else if (cookie) {
            this.bing = cookie;
        }
    }

    // Start the session by creating a session cookie called '_sea'.
    // Load the Bing library if session is bing
    sessionStart() {
        const sessionCookie = this.getCookie("_sea");

        if (this.session) {
            this.setCookie("_sea", this.session, 30); // 30 minutes
        } else if (sessionCookie) {
            this.setCookie("_sea", sessionCookie, 30); // extend session
            this.session = sessionCookie;
        }

        // Load Bing library, when needed.
        if (this.session === "bing") {
            window.uetq = window.uetq || [];

            const loaded = function () {
                window.uetq = new UET({ti: "4057429", q: window.uetq});
                window.uetq.push("pageLoad");
            };

            const script = document.createElement("script");
            script.src = "//bat.bing.com/bat.js";
            script.async = true;
            script.onload = script.onreadystatechange = function () {
                const s = this.readyState;
                if (!s || s === "loaded" || s === "complete") {
                    loaded();
                    script.onload = script.onreadystatechange = null;
                }
            };

            const firstScript = document.getElementsByTagName("script")[0];
            firstScript.parentNode.insertBefore(script, firstScript);
        }
    }

    // Wrapper for console.log; only outputs when debug is true.
    say(text) {
        if (this.debug && window.console && window.console.log) {
            // eslint-disable-next-line no-console
            console.log(text);
        }
    }

    // Get the stored gclid
    // returns 'null' if gclid is not present
    getGclid() {
        return this.gclid;
    }

    // Get the stored bing id
    // returns 'null' if bing id is not present
    getBingID() {
        return this.bing;
    }

    // Get an url parameter
    // returns 'null' if parameter is not present
    getParam(p) {
        const match = new RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    // Get a cookie
    // returns 'null' if cookie is not present
    getCookie(key) {
        if (!key) {
            return null;
        }
        key = encodeURIComponent(key).replace(/[-.+*]/g, "\\$&");
        const pattern = new RegExp("(?:(?:^|.*;)\\s*" + key + "\\s*\\=\\s*([^;]*).*$)|^.*$");
        const value = document.cookie.replace(pattern, "$1");
        return decodeURIComponent(value) || null;
    }

    // Set a cookie
    setCookie(name, value, minutes) {
        this.say("cookie " + name + " set");
        const date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
        const expires = "; expires=" + date.toGMTString();
        const path = "; path=/";
        document.cookie = name + "=" + value + expires + path;
    }

    // Fire pixels
    // - pixels are fired only if necessary, e.g. sea session was detected
    // - the correct pixels are fired based on the profile
    //     a) bing pixel is called for all profiles
    //     b) adwords pixel is different per profile
    fire() {

        if (!this.consent) {
            this.say("Fire blocked because we have no consent");
            return;
        }

        const adwordsPixel = "//www.googleadservices.com/pagead/conversion/1003358662/" +
            "?label=JgIfCLzW0VsQxpO43gM&guid=ON&script=0";
        if (this.session === "adwords") {
            this.pixel(adwordsPixel);
            this.say("fired adwords! " + this.gclid);
        }

        if (this.session === "bing") {
            window.uetq = window.uetq || [];
            window.uetq.push({"ec": "event category", "ea": "Clickout", "el": "event label", "ev": "0"});
            this.say("fired bing! " + this.bing);
        }
    }

    // Call the pixel url, with cache buster
    pixel(url) {
        const pxl = new Image();
        pxl.src = url + "&cb=" + new Date().getTime();
    }
}

let instance = undefined;

export default function getSeaTracker(profile, consent, debug) {
    if (instance === undefined) {
        instance = new SEATracker(consent, debug);
        instance.profile = profile;
    }

    return instance;
}

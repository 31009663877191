/* eslint-disable max-len */
import Utils from "./../utilities";
import {ValidationMessages} from "./validation-messages";

export default class FormValidator {
    constructor(form, fields) {
        if (Utils.isNullOrUndefined(form) || Utils.isNullOrUndefined(fields)) {
            throw new Error("FormValidator requires a form and fields to function properly");
        }
        this.form = form;
        this.fields = fields;

        this._validateOnEntry();
        this._validateOnBlur();
    }

    _validateOnEntry() {
        this.fields.forEach((field) => {
            const input = this.form.querySelector(`#${field}`);

            if (input) {
                input.addEventListener("input", () => {
                    if (input.dataset.dirty === "true") {
                        this.validateField(input);
                    }
                });
            } else {
                console.error(`No input present with id #${field}`);
            }
        });
    }

    _validateOnBlur() {
        this.fields.forEach((field) => {
            const input = this.form.querySelector(`#${field}`);

            if (input) {
                input.addEventListener("blur", () => {
                    // Mark input as dirty so key-entries will trigger validation (see validateOnEntry)
                    input.dataset.dirty = true;
                    this.validateField(input);
                });
            } else {
                console.error(`No input present with id #${field}`);
            }
        });
    }

    _ensureFieldIsElement(field) {
        if (typeof field === "string") {
            return this.form.querySelector(`#${field}`) || this.form.querySelector(`input[name='${field}']`);
        } else {
            return field;
        }
    }

    // Methods for public use
    validate() {
        const validationResults = [];

        this.fields.forEach((field) => {
            const input = this.form.querySelector(`#${field}`);

            if (input) {
                // Mark all input as dirty so key-entries will trigger validation (see validateOnEntry)
                input.dataset.dirty = true;
                validationResults.push(this.validateField(input));
            } else {
                console.error(`No input present with id #${field}`);
            }
        });

        const validationSuccess = validationResults.every(Boolean);
        return validationSuccess;
    }

    validateField(field) {
        field = this._ensureFieldIsElement(field);

        let fieldName = "";
        const groupElement = field.closest(".group-element") || field.closest(".form-group");
        if (groupElement.querySelector("label")) {
            fieldName = groupElement.querySelector("label").innerText;
        } else {
            fieldName = field.getAttribute("placeholder");
        }

        if (field.type === "radio") {
            const radioButtonGroupName = field.getAttribute("name");
            const allRadioButtons = document.querySelectorAll(`input[name='${radioButtonGroupName}']`);
            let checked = false;
            allRadioButtons.forEach(radioButton => {
                if (radioButton.checked) {
                    checked = true;
                    return;
                }
            });

            if (!checked) {
                this.setStatus(field, ValidationMessages.EMPTY_FIELD.replace("{fieldName}", field.dataset.fieldName.toLowerCase()), "error");
                return false;
            } else {
                this.setStatus(field, null, "success");
            }
        }

        if (field.dataset.validationType !== "password-confirmation") {
            // Check presence of values
            if (field.value.trim() === "") {
                this.setStatus(field, ValidationMessages.EMPTY_FIELD.replace("{fieldName}", fieldName.toLowerCase()), "error");
                return false;
            } else {
                this.setStatus(field, null, "success");
            }
        }

        // Check for valid emailaddress
        if (field.dataset.validationType === "email") {
            const re = /\S+@\S+\.\S+/;
            if (re.test(field.value)) {
                this.setStatus(field, null, "success");
            } else {
                this.setStatus(field, ValidationMessages.EMAIL_INVALID, "error");
                return false;
            }
        }

        // Check password confirmation
        if (field.dataset.validationType === "password-confirmation") {
            const passwordField = this.form.querySelector("input[data-validation-type='password']");

            if (!passwordField) {
                console.debug("FormValidator: password-confirmation validation requires an input with data-validation-type='password' to be present");
            } else if (field.value.trim() === "") {
                this.setStatus(field, ValidationMessages.PASSWORD_CONFIRMATION_EMPTY, "error");
                return false;
            } else if (field.value !== passwordField.value) {
                this.setStatus(field, ValidationMessages.PASSWORDS_NOT_EQUAL, "error");
                return false;
            } else {
                this.setStatus(field, null, "success");
            }
        }

        return true;
    }

    setStatus(field, message, status) {
        field = this._ensureFieldIsElement(field);

        if (!field) {
            this.setGenericStatus(message, status);
            return;
        }

        let groupElement = field.closest(".group-element");
        if (Utils.isNullOrUndefined(groupElement)) {
            groupElement = field.closest(".form-group");
        }

        const successIcon = groupElement.querySelector(".icon-success");
        const errorIcon = groupElement.querySelector(".icon-error");
        const errorMessage = groupElement.querySelector(".error-message");

        if (status === "success") {
            if (errorMessage) {
                errorMessage.innerText = "";
                errorMessage.classList.add("hidden");
            }
            field.classList.remove("validation--error");
            field.classList.add("validation--success");
            if (errorIcon) {
                errorIcon.classList.add("hidden");
            }
            if (successIcon) {
                successIcon.classList.remove("hidden");
            }
        } else if (status === "error") {
            if (errorMessage) {
                errorMessage.innerText = message;
                errorMessage.classList.remove("hidden");
            }
            field.classList.add("validation--error");
            field.classList.remove("validation--success");
            if (successIcon) {
                successIcon.classList.add("hidden");
            }
            if (errorIcon) {
                errorIcon.classList.remove("hidden");
            }
        } else {
            // Reset status and message
            if (errorMessage) {
                errorMessage.innerText = message;
                errorMessage.classList.add("hidden");
            }
            field.classList.remove("validation--error");
            field.classList.remove("validation--success");
            if (successIcon) {
                successIcon.classList.add("hidden");
            }
            if (errorIcon) {
                errorIcon.classList.add("hidden");
            }
        }
    }

    setGenericStatus(message, status) {
        const genericMessageGroup = this.form.querySelector(".generic-message");
        const messageTextElement = genericMessageGroup.querySelector(".text");

        if (genericMessageGroup) {
            if (message) {
                messageTextElement.innerText = message;
                if (status === "success") {
                    messageTextElement.classList.remove("validation--error");
                    messageTextElement.classList.add("validation--success");
                }

                if (status === "error") {
                    messageTextElement.classList.remove("validation--success");
                    messageTextElement.classList.add("validation--error");
                }
                genericMessageGroup.classList.remove("hidden");
            } else {
                messageTextElement.innerText = "";
                messageTextElement.classList.remove("validation--success");
                messageTextElement.classList.remove("validation--error");
                genericMessageGroup.classList.add("hidden");
            }
        }
    }

    clearValidation() {
        this.clearGenericValidationMessage();

        // Reset validation for fields
        this.fields.forEach((field) => {
            const input = this.form.querySelector(`#${field}`);
            if (input) {
                input.dataset.dirty = false;
                this.setStatus(field, "", "");
            }
        });
    }

    clearGenericValidationMessage() {
        // Reset generic validation
        const genericMessageGroup = this.form.querySelector(".generic-message");
        const messageTextElement = genericMessageGroup.querySelector(".text");

        if (genericMessageGroup) {
            messageTextElement.innerText = "";
            messageTextElement.classList.remove("validation--error");
            messageTextElement.classList.remove("validation--success");
            genericMessageGroup.classList.add("hidden");
        }
    }
}

import $ from "jquery";

import "../../scss/widgets/traffic-map.scss";
import ProfileAgent from "./profile";
import WidgetBase from "./rainbow-base";

/**
 * The TrafficMapWidget widget allows you to see a visual map of the current
 * traffic jams.
 * It is possible to select a region which will show the traffic jams in this
 * selected area. It will allso show 2 tabs containing the traffic jams
 * information and speed traps.
 * The speed traps are not filtered on the selection. Those are an overview of
 * all speed traps in the Netherlands.
 */
export class TrafficMapWidget extends WidgetBase {
    constructor(element) {
        super(element);

        this.init();

        this.region = $(".region select", element);
        this.region.on({
            "change": () => {
                const region = this.region.val();
                ProfileAgent.set("traffic_region", region);
            }
        });

        ProfileAgent.onChange("traffic_region", (e, region) => {
            if (region) {
                this.region.val(region);
                this.initComponentHtml(region);
            }
        });
    }

    /**
     * Init will select all new elements that are injected into the dom and
     * assign click events to them
     */
    init() {
        this.trafficJamContentElement = $(".traffic-jams", this.element);
        this.speedTrapContentElement = $(".speed-traps", this.element);
        this.trafficJamTabElement = $(".traffic-jam-tab", this.element);
        this.speedTrapTabElement = $(".speed-trap-tab", this.element);

        this.on({
            "click .traffic-jam-tab": () => this.selectTab("traffic-jam"),
            "click .speed-trap-tab": () => this.selectTab("speed-trap"),
        });
    }

    /**
     * This is the main tab toggler function to handle on the click event.
     * @param tabName
     */
    selectTab(tabName) {
        if (tabName === "traffic-jam") {
            this.trafficJamContentElement.show();
            this.speedTrapContentElement.hide();
            this.trafficJamTabElement.addClass("selected");
            this.speedTrapTabElement.removeClass("selected");
        } else {
            this.trafficJamContentElement.hide();
            this.speedTrapContentElement.show();
            this.trafficJamTabElement.removeClass("selected");
            this.speedTrapTabElement.addClass("selected");
        }
    }

    /**
     * Refresh the current widget content by injecting HTML retrieved from the backend.
     *
     * @param region
     */
    initComponentHtml(region) {
        this.reloadComponentHtml("[data-widget-content]", {region}).then(() => {
            this.init();
        });
    }
}

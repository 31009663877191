export const requestWidgetHtml = async (options, params) => {
    if (typeof options === "string") {
        options = {url: options};
    }
    if (!options.url) {
        throw new Error("A URL must be passed as a option to request");
    }

    const urlParams = params ? `?${new URLSearchParams(params)}` : "";

    return await fetch(`${options.url}${urlParams}`, {
        method: "GET",
        headers: {
            "Content-type": "text/html; charset=UTF-8",
        },
    }).then((res) => res.text()).then(data => data);
};

// This code is based on the `js_catalog_template` found in Django, rewritten as an ES6 module.
// See https://github.com/django/django/blob/1.11.12/django/views/i18n.py#L90 for the original source.
//
// Changes from the original:
// - Instead of including the message catalog in the source, it is loaded from
//   a JSON file.
// - Formats are not supported

import {default as profileCatalog} from "../../profile/locale/nl_NL/LC_MESSAGES/djangojs.po";
import {default as searchCatalog} from "../../search/locale/nl_NL/LC_MESSAGES/djangojs.po";
import {default as themasCatalog} from "../../themas/locale/nl_NL/LC_MESSAGES/djangojs.po";
import {default as umbrellaCatalog} from "../../umbrella/locale/nl_NL/LC_MESSAGES/djangojs.po";
import {default as rainbowCatalog} from "../locale/nl_NL/LC_MESSAGES/djangojs.po";

const catalog = {
    ...rainbowCatalog,
    ...profileCatalog,
    ...searchCatalog,
    ...themasCatalog,
    ...umbrellaCatalog
};

let enabled = true;

function pluralidx(count) {
    return count === 1 ? 0 : 1;
}

function lookup(key) {
    return enabled ? catalog[key] : undefined;
}

export function disableGettext() {
    enabled = false;
}

export default function gettext(msgid) {
    const value = lookup(msgid);
    if (value === undefined) {
        return msgid;
    }
    return typeof value === "string" ? value : value[0];
}

export function ngettext(singular, plural, count) {
    const value = lookup(singular);
    if (value === undefined) {
        return count === 1 ? singular : plural;
    }
    return value[pluralidx(count)];
}

export function gettextNoop(msgid) {
    return msgid;
}

export function pgettext(context, msgid) {
    const value = gettext(context + "\x04" + msgid);
    if (value.indexOf("\x04") !== -1) {
        return msgid;
    }
    return value;
}

export function npgettext(context, singular, plural, count) {
    const value = ngettext(context + "\x04" + singular, context + "\x04" + plural, count);
    if (value.indexOf("\x04") !== -1) {
        return ngettext(singular, plural, count);
    }
    return value;
}

export function interpolate(fmt, obj, named) {
    if (named) {
        return fmt.replace(/%\(\w+\)s/g, match => String(obj[match.slice(2, -2)]));
    }

    return fmt.replace(/%s/g, () => String(obj.shift()));
}

import $ from "jquery";
import _ from "lodash";

import "../../../scss/widgets/searchbox/lightbox.scss";
import breakpoints from "../../breakpoints";
import {checkConsentContent} from "../../consent";
import getUrlParam from "../../url-params";

import lightboxHtml from "!!raw-loader!./lightbox.html";

const defaultSearchUrl = "https://www.startpagina.nl/search";

export default class Lightbox {
    constructor(element, options) {
        this.element = element;

        this.container = this.createLightbox(options);
        this.container.appendTo($("body"));

        this.lightbox = $(".lightbox", this.container);
        this.content = $(".content", this.container);
        this.historyPrevious = $(".search-history-back", this.container);
        this.historyNext = $(".search-history-next", this.container);
        this.closeButton = $(".close", this.container);

        this.siteContent = $(".header + .container");

        //store windows width to check if it was changed
        // when resize event triggered
        this.lastWindowWidth = $(window).width();

        $(".close, .back, .overlay", this.container).on("click", e => {
            e.preventDefault();
            $(".search-field", this.element).val("");
            this.close();
        });
        $(".search-google", this.container).on("click", e => {
            e.preventDefault();
            this.trigger("google");
        });
        $(window).on("resize", () => this.onResize());

        this.url = options.url || defaultSearchUrl;
        if (options.requestType) {
            this.requestType = options.requestType;
        } else {
            this.requestType = window.location.hostname.endsWith(".startpagina.nl") ? "html" : "jsonp";
        }

        this.minLength = 2;
        this.updateTimeout = 500;

        this.params = {
            r: options.reorderLinks || options.reorderLinks === undefined ? "on" : "off",
            o: "html", // output html
            channel: "desktop",
            template: "five-columns"
        };

        this.lastQuery = null;
        this.lastSuggest = false;
        this.searchRequest = null;
        this.currentPage = null;
        this.update = _.throttle(this.update.bind(this), this.updateTimeout, {leading: false});
    }

    createLightbox(options) {
        const container = $(lightboxHtml);

        const header = container.find(".header .freetext");
        if (options.headerText) {
            header.html(options.headerText);
        }

        const footer = container.find(".footer .freetext");
        if (options.footerText) {
            footer.html(options.footerText);
        }

        return container;
    }

    adjustPosition() {
        const position = this.element.offset();
        let top = 0;

        // do not add offset on mobile
        // it causes lightbox to go down on scroll when autocomplete opened
        if (this.isMediumScreen()) {
            top += $("#cookiebar-top").height() || 0;
            top += $(".preview-bar").height() || 0;
        } else {
            top += position.top;
        }

        this.lightbox.css("top", top + "px");

        if (this.isMediumScreen()) {
            this.lightbox.find("> .content-wrapper").css({
                "margin-top": this.element.height()
            });
        } else {
            this.lightbox.find("> .header").css({
                "height": this.element.height(),
                "line-height": `${this.element.height()}px`
            });
        }
    }

    onResize() {
        //on iOS Safari resize event triggered on scrolling
        //we are checking if width actually changed to prevent unwanted lightbox reload
        if (this.isOpen() && this.lastWindowWidth !== $(window).width()) {
            if (this.element.is(":hidden")) {
                this.close();
            } else {
                this.update(this.lastQuery);
            }
            this.lastWindowWidth = $(window).width();
        }
    }

    trigger(eventType, data) {
        const event = $.Event(eventType);
        $(this).triggerHandler(event, data);
        return event;
    }

    on(eventType, handler) {
        $(this).on(eventType, handler);
    }

    isOpen() {
        return this.container.is(":visible");
    }

    open() {
        this.trigger("open");
        $("body").addClass("lightbox-open");
        this.adjustPosition();

        this.container.show();
        if (this.isMediumScreen()) {
            this.onMobileOpen();
        }
    }

    close() {
        this.trigger("close");
        this.update.cancel();
        // onMobieClose called without channel check in case screen was resized
        this.onMobileClose();
        this.container.hide();
        this.currentPage = null;
        this.lastQuery = null;
        $("body").removeClass("lightbox-open");
    }

    normalize(query) {
        return $.trim(query).toLowerCase();
    }

    update(query) {
        query = this.normalize(query);
        this.search(query);
    }

    search(query, suggest = false) {
        // Don't search for the same query.
        if (query === this.lastQuery && suggest === this.lastSuggest) {
            return;
        }

        // Don't do a search without suggestions after one with (for the same query).
        if (query === this.lastQuery && this.lastSuggest && !suggest) {
            return;
        }

        // Cancel the current request, after clearing the error handler.
        if (this.searchRequest) {
            this.searchRequest.error = $.noop;
            this.searchRequest.abort();
        }

        this.lastQuery = query;
        this.lastSuggest = !!suggest;

        if (query.length < this.minLength) {
            this.searchRequest = null;
            this.render(query);
            return;
        }

        this.searchRequest = $.ajax({
            url: this.url,
            data: $.extend({}, this.params, {
                q: query,
                suggest: !!suggest,
                preview_date: getUrlParam("preview_date"),
                preview_time: getUrlParam("preview_time"),
                preview_ts: getUrlParam("preview_ts"),
            }),
            dataType: this.requestType,
            cache: true,
            success: (response, status, xhr) => {
                this.render(query, response, status, xhr.getResponseHeader("X-SP-Page"));
            },
            error: (xhr, status) => this.render(query, null, status),
        });
    }

    render(query, response, status, pageId = null) {
        this.trigger("render", {query, status, response});

        if (!response) {
            this.close();
            return;
        }

        if (pageId === this.currentPage) {
            return;
        }

        this.currentPage = pageId;
        this.content.html(response);
        this.addTrackingData(query);
        this.reorderBlocks();
        checkConsentContent(this.content);

        this.open();
    }

    addTrackingData(query) {
        const blocks = this.content.find(".block");

        const testBlocks = blocks.filter("[data-has-variants]");
        const testVariants = $.map(testBlocks, block =>
            $(block).data("trackBlockTrackingId") + "," + $(block).data("trackOriginalTrackingId")
        );

        const feedBlocks = blocks.filter("[class$='search-result']");
        const resultTypes = ["filteredsearchresult"];

        $.each(feedBlocks, (i, block) => {
            for (const match of $(block).attr("class").match(/[a-z0-9]*-search-result/ig)) {
                if (resultTypes.indexOf(match) === -1) {
                    resultTypes.push(match);
                }
            }
        });

        this.content.data({
            trackQuery: query,
            trackSearchTestVariants: testVariants.length ? testVariants.join(";") : null,
            trackResultType: resultTypes.join(","),
        });
    }

    onMobileOpen() {
        // site content hidden to prevent scrolling through content
        // on background
        this.siteContent.hide();
        this.closeButton.show();
        this.container[0].scrollIntoView();
    }

    onMobileClose() {
        this.siteContent.show();
    }

    isMediumScreen() {
        const width = document.documentElement.clientWidth;
        return width < parseInt(breakpoints.breakpointMedium, 10);
    }

    numColumns() {
        const width = document.documentElement.clientWidth;
        if (width >= parseInt(breakpoints.breakpointLarge, 10)) {
            return 5;
        }
        if (width >= parseInt(breakpoints.breakpointMedium, 10)) {
            return 3;
        }
        if (width >= parseInt(breakpoints.breakpointSmall, 10)) {
            return 2;
        }
        return 1;
    }

    reorderBlocks() {
        const numColumns = this.numColumns();
        if (numColumns === 2) {
            this.content.find(".column-1 .block").appendTo(this.content.find(".column-2"));
        }
        if (numColumns === 2 || numColumns === 3) {
            this.content.find(".column-4 .block").appendTo(this.content.find(".column-3"));
        }
    }
}
